import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import RangeSlider from "react-bootstrap-range-slider";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useFilter } from "../../Contexts/filterContext";
import { useCurr } from "../../Contexts/currContext";
import { Skeleton } from "@mui/material"; // Import Skeleton from MUI

export const LeftFilter = ({ allData }) => {
  const [catData, setCatData] = useState([]);
  const [prodTags, setProdTags] = useState([]);
  const { selectedCurrency, currConvert } = useCurr();
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (allData.cat_arr) {
      setCatData(allData.cat_arr);
    }
    if (allData.arr_prod_tag) {
      setProdTags(allData.arr_prod_tag);
    }
    setIsLoading(false);
  }, [allData]);

  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);

  const handleInputClick2 = () => {
    setIsModalOpen2(true);
  };

  const handleCloseModal1 = () => {
    setIsModalOpen1(false);
  };

  const handleCloseModal2 = () => {
    setIsModalOpen2(false);
  };

  //const [startDate, setStartDate] = useState(null);
  // Set the minDate to the current date to disable previous dates
  const minDate = new Date();
  minDate.setDate(minDate.getDate() + 1);

  const { price_value, handleFilters, locationName } = useFilter();

  const [selectedDate, setSelectedDate] = useState(null);
  const [formattedDate, setFormattedDate] = useState("");
  const handleApplyDate = () => {
    setIsModalOpen2(false);
    const formattedDate = selectedDate
      ? selectedDate.toLocaleDateString("en-GB")
      : "";
    const formattedDate_escl = selectedDate
      ? selectedDate.toLocaleDateString("es-CL")
      : "";
    setFormattedDate(formattedDate);
    handleFilters("", "", "", "", "", "", null, null, formattedDate_escl);
  };

  return (
    <div className="col-md-4 col-lg-3 mobile-none">
      {isLoading ?
        <div className="sidebar sticky-top mobile-none">
           <Skeleton animation="wave" variant="rectangular" width="100%" height={500} />
        </div>
      :
      <div className="sidebar sticky-top mobile-none">
        <div className="position-relative d-flex align-items-center">
          <span className="position-absolute mt--12 mx-4">
            <i className="bi bi-search"></i>
          </span>
          <input
            type="search"
            className="ps-12 form-control"
            placeholder="search for activities"
            onKeyUp={(event) =>
              handleFilters(
                "",
                "",
                "",
                "",
                "",
                "",
                event.target.value,
                null,
                null
              )
            }
          />
        </div>
        <div className="position-relative d-flex align-items-center">
          <span className="position-absolute mt--12 mx-4">
            <i className="bi bi-geo-alt"></i>
          </span>
          <input
            type="search"
            id="location"
            className="ps-12 form-control"
            placeholder="search location"
            value={locationName || ""}
            onChange={(event) =>
              handleFilters(
                "",
                "",
                "",
                "",
                "",
                "",
                null,
                event.target.value,
                null
              )
            }
          />
        </div>

        <div className="position-relative d-flex align-items-center">
          <span className="position-absolute mt--12 mx-4">
            <i className="bi bi-calendar"></i>
          </span>
          <input
            type="text"
            className="form-control ps-12"
            defaultValue={formattedDate}
            readOnly
            onClick={handleInputClick2}
            placeholder="add dates"
            name=""
            id="datepicker1"
            onKeyUp={(event) =>
              handleFilters(
                "",
                "",
                "",
                "",
                "",
                "",
                null,
                null,
                event.target.value
              )
            }
          />
        </div>
        <hr />
        <div className="price-range">
          <h6>Price range</h6>
          <RangeSlider
            value={price_value}
            onChange={(event) =>
              handleFilters(
                "",
                event.target.value,
                "",
                "",
                "",
                "",
                null,
                null,
                null
              )
            }
            className="custom-slider"
            min={0}
            max={5000}
          />
          <div className="d-flex justify-content-between pt-2">
            <span>{selectedCurrency} 0</span>
            <span>
              {selectedCurrency} {currConvert(price_value)}
            </span>
          </div>
        </div>
        <hr />
        <h6>Category</h6>

        {catData.map((item, index) => (
          <div className="form-check" key={index}>
            <input
              className="form-check-input"
              type="checkbox"
              name=""
              id={"fc2" + item.cat_id}
              onClick={() =>
                handleFilters(item.cat_id, "", "", "", "", "", null, null, null)
              }
              value=""
            />
            <label className="form-check-label" htmlFor={"fc2" + item.cat_id}>
              {item.cat_name}
            </label>
          </div>
        ))}

        <hr />
        <h6>Sort by</h6>
        <div className="form-check">
          <input
            className="form-check-input"
            type="radio"
            name="sort-by"
            id="1"
            onClick={(event) =>
              handleFilters("", "", "", "1", "", "", null, null, null)
            }
            value=""
          />
          <label className="form-check-label" htmlFor="1">
            Recommended
          </label>
        </div>
        <div className="form-check">
          <input
            className="form-check-input"
            type="radio"
            name="sort-by"
            id="priceL-H"
            onClick={(event) =>
              handleFilters("", "", "", "2", "", "", null, null, null)
            }
            value=""
          />
          <label className="form-check-label" htmlFor="priceL-H">
            Price: Low to High
          </label>
        </div>
        <div className="form-check">
          <input
            className="form-check-input"
            type="radio"
            name="sort-by"
            id="priceH-L"
            onClick={(event) =>
              handleFilters("", "", "", "3", "", "", null, null, null)
            }
            value=""
          />
          <label className="form-check-label" htmlFor="priceH-L">
            Price: High to Low
          </label>
        </div>
        <div className="form-check">
          <input
            className="form-check-input"
            type="radio"
            name="sort-by"
            id="popularity"
            onClick={(event) =>
              handleFilters("", "", "", "4", "", "", null, null, null)
            }
            value=""
          />
          <label className="form-check-label" htmlFor="popularity">
            Popularity Score: High to Low
          </label>
        </div>
        <hr />
        <h6>Others</h6>
        {prodTags.map((item, index) => (
          <div className="form-check" key={index}>
            <input
              className="form-check-input"
              type="checkbox"
              name=""
              id={"fp2" + item.pro_tag_id}
              value=""
              onClick={(event) =>
                handleFilters(
                  "",
                  "",
                  "",
                  "",
                  item.pro_tag_id,
                  "",
                  null,
                  null,
                  null
                )
              }
            />
            <label
              className="form-check-label"
              htmlFor={"fp2" + item.pro_tag_id}
            >
              {item.pro_tag_name}
            </label>
          </div>
        ))}
      </div>
      }
      {/* First Modal */}
      {isModalOpen1 && (
        <Modal show={isModalOpen1} onHide={handleCloseModal1} scrollable centered>
          <Modal.Header closeButton>
            <Modal.Title>
              <form className="w-100" action="#">
                <div className="position-relative d-flex align-items-center select-date">
                  <span className="svg-icon svg-icon-2 position-absolute mx-4">
                    <i className="bi bi-search"></i>
                  </span>
                  <input
                    type="search"
                    className="form-control ps-12"
                    placeholder="Search for destination"
                  />
                </div>
              </form>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="region pb-4">
              <h5>Australia</h5>
              <input
                type="radio"
                className="btn-check"
                name="options-outlined"
                id="11"
                autoComplete="off"
              />
              <label className="btn btn-outline-primary" htmlFor="1">
                Brisbane
              </label>
              <input
                type="radio"
                className="btn-check"
                name="options-outlined"
                id="2"
                autoComplete="off"
              />
              <label className="btn btn-outline-primary" htmlFor="2">
                Broome
              </label>
              <input
                type="radio"
                className="btn-check"
                name="options-outlined"
                id="3"
                autoComplete="off"
              />
              <label className="btn btn-outline-primary" htmlFor="3">
                Melbourne
              </label>
              <input
                type="radio"
                className="btn-check"
                name="options-outlined"
                id="4"
                autoComplete="off"
              />
              <label className="btn btn-outline-primary" htmlFor="4">
                Gold coast
              </label>
              <input
                type="radio"
                className="btn-check"
                name="options-outlined"
                id="5"
                autoComplete="off"
              />
              <label className="btn btn-outline-primary" htmlFor="5">
                Hunter valley
              </label>
              <input
                type="radio"
                className="btn-check"
                name="options-outlined"
                id="6"
                autoComplete="off"
              />
              <label className="btn btn-outline-primary" htmlFor="6">
                Perth
              </label>
            </div>
            <div className="region pb-4">
              <h5>Barzil</h5>
              <input
                type="radio"
                className="btn-check"
                name="options-outlined"
                id="7"
                autoComplete="off"
              />
              <label className="btn btn-outline-primary" htmlFor="7">
                Foz do Iguaçu
              </label>
              <input
                type="radio"
                className="btn-check"
                name="options-outlined"
                id="8"
                autoComplete="off"
              />
              <label className="btn btn-outline-primary" htmlFor="8">
                Foz do Iguaçu
              </label>
            </div>
            <div className="region pb-4">
              <h5>France</h5>
              <input
                type="radio"
                className="btn-check"
                name="options-outlined"
                id="9"
                autoComplete="off"
              />
              <label className="btn btn-outline-primary" htmlFor="9">
                Paris
              </label>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleCloseModal1}>
              Apply
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      {/* Second Modal */}
      {isModalOpen2 && (
        <Modal
          show={isModalOpen2}
          onHide={handleCloseModal2}
          className="calendar"
          size="lg"
          centered
          scrollable
        >
          <Modal.Header closeButton>
            <Modal.Title>Select Dates</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <DatePicker
              renderCustomHeader={({
                monthDate,
                customHeaderCount,
                decreaseMonth,
                increaseMonth,
              }) => (
                <div>
                  <button
                    aria-label="Previous Month"
                    className={
                      `react-datepicker__navigation react-datepicker__navigation--previous ${
                        customHeaderCount === 1 ? 'invisible' : ''
                      }`
                    }
                    onClick={decreaseMonth}
                  >
                    <span
                      className={
                        "react-datepicker__navigation-icon react-datepicker__navigation-icon--previous"
                      }
                    >
                      {"<"}
                    </span>
                  </button>
                  <span className="react-datepicker__current-month">
                    {monthDate.toLocaleString("en-US", {
                      month: "long",
                      year: "numeric",
                    })}
                  </span>
                  <button
                    aria-label="Next Month"
                    className={
                      `react-datepicker__navigation react-datepicker__navigation--next ${
                        customHeaderCount === 0 ? 'invisible' : ''
                      }`
                    }
                    onClick={increaseMonth}
                  >
                    <span
                      className={
                        "react-datepicker__navigation-icon react-datepicker__navigation-icon--next"
                      }
                    >
                      {">"}
                    </span>
                  </button>
                </div>
              )}
              selected={selectedDate}
              onChange={(date) => setSelectedDate(date)}
              monthsShown={2}
              inline
              minDate={minDate}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleApplyDate}>
              Apply
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};
