import React from 'react'
import {Helmet} from "react-helmet";
import { Link } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import AboutHighlights from './AboutHighlights'
import OurPartners from './OurPartners'
import { AboutContent } from './AboutContent';
import BASE_URLS from '../../baseUrl';

export const About = () => {
    const webUrl = BASE_URLS.webUrl;
  return (
    <div className='body-content'>  
        <Helmet>
            <title>About Us | Rathin Tourism LLC</title>
        </Helmet>
        <div className="breadcrumbs">
            <Container>
                <div className="d-flex justify-content-between align-items-center">
                <ol>
                    <li><Link to={webUrl}>Home</Link></li>
                    <li>About Us</li>
                </ol>
                </div>
            </Container>
        </div>
        <AboutContent />
        <AboutHighlights />
        <OurPartners />
    </div>
  )
}
