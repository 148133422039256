import React, { useEffect } from 'react';
import { useWishlist } from "../../Contexts/WishlistContext";
import { WishlistButton } from "../WishList/WishlistButton";
import { useCurr } from "../../Contexts/currContext";
import BASE_URLS from "../../baseUrl"; import Button from 'react-bootstrap/Button';

export const MobileBottom = ({ proData }) => {
  const { selectedCurrency, currConvert } = useCurr();

  const id = proData.products_id;
  const product_slug = proData.product_slug;
  const mainApiUrl = BASE_URLS.main;
  const {
    getProductsData,
    getWishlistItems,
    wishlistItems,
    actSidebarWishlist,
    setActSidebatwishlist,
  } = useWishlist();

  useEffect(() => {
    getProductsData(product_slug);

    getWishlistItems();
  }, [product_slug, mainApiUrl]);

  useEffect(() => {
    if (proData && wishlistItems) {
      const initialWishlist = {};
      initialWishlist[id] = wishlistItems.some(
        (item) => item.product_id === id
      );
      setActSidebatwishlist(initialWishlist);


    }
  }, [wishlistItems, proData]);

  return (
    <section className="mobile-bottom d-none mobile-block">
      <div className="container">
        <div className="row">
          <div className="d-flex justify-content-between align-items-center">
            <p className="font-weight-bold mb-0">
              {/* <WishlistButton
								      heartToggle={actSidebarWishlist[proData.products_id]}
								      product={proData.products_id}
							      /> Save */}
              <span>
                <small className="text-muted">from</small><br></br>{" "}
                <span className="price">
                  {selectedCurrency} {currConvert(proData.min_original_price)} {proData.min_original_price < proData.original_price1 ? (<span className="hot_badge">{proData.discount}</span>) : null}
                </span>
              </span>
            </p>
            <Button variant='primary' href="#next-dates-coupon"> Book now</Button>
          </div>
        </div>
      </div>
    </section>
  )
}
