import React from 'react'
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import wishlist from '../../Images/wishlist.svg';
import { Button } from 'react-bootstrap';
import BASE_URLS from '../../baseUrl';

const EmptyWishlist = () => {
  const webUrl = BASE_URLS.webUrl;
  return (
    <section>
        <Container>
            <Row className='text-center'>
                <Col md={{ span: 4, offset: 4 }}>
                    <h4 className='font-weight-bold'>Make Your Wishlist</h4>
                    <img src={wishlist} width="250px" height="auto" className='img-fluid pt-3 pb-3' alt="Empty Wishlist" loading="lazy"/>
                    <p>Find an activity you like? Click on the <i className='bi bi-heart'></i> to save it here and view your wishlist from all your devices.</p>
                    <Link to={`${webUrl}activity-list`}><Button variant="primary">Find to do activities</Button></Link>
                </Col>
            </Row>
        </Container>
    </section>
  )
}
export default EmptyWishlist;