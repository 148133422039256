import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

export const RatingLists = ({ custRat, webUrl, mainApiUrl, updateOrderComplete }) => {
    const [custRatState, setCustRatState] = useState([]);
    const usr_id = localStorage.getItem("setAuthUser");
    useEffect(() => {
        //console.log('RatingLists custRat changed:', custRat);
        setCustRatState(custRat || []);
    }, [custRat]);

    // Use another useEffect to handle the updateOrderComplete changes
    useEffect(() => {
        // When updateOrderComplete changes, update the custRatState
        updateOrderCompleteHandler();
    }, [updateOrderComplete]);

    const updateOrderCompleteHandler = async () => {
        try {
            // Fetch the latest reviews after updating orderComplete
            const res = await fetch(`${mainApiUrl}/get_cust_review.php?user_id=${usr_id}`);
            const getdata = await res.json();

            if (getdata && getdata.review_list) {
                //console.log('Updated Review list from server:', getdata.review_list);
                setCustRatState(getdata.review_list);
            }
        } catch (error) {
            console.error('Error fetching updated data:', error);
        }
    };

    const formatReviewDate = (dateString) => {
        const options = {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour12: false,
        };

        const formattedDate = new Date(dateString).toLocaleString('en-US', options);
        return formattedDate;
    };

    return (
        <div className='rating'>
            {custRatState.map((review) => (
                <div className="review-list" key={review.review_id}>
                    <h4>{review.product_review_title}</h4>
                    <div className='d-flex'>
                        <div className="review-star">
                            {[...Array(5)].map((_, index) => (
                                <i key={index} className={index < review.product_rating ? "bi bi-star-fill" : "bi bi-star"}></i>
                            ))}
                        </div>
                        <div className='review-date'>- {formatReviewDate(review.created_dtm)}</div>
                    </div>
                    <p>{review.product_review}</p>
                    <h6>
                        <Link to={`${webUrl}activity/${review.product_slug}`} title={review.product_slug}>
                            {review.product_name}
                        </Link>
                    </h6>
                </div>
            ))}
        </div>
    );
};
