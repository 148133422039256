import { useState, useEffect, useLayoutEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Container, Row } from "react-bootstrap";
import { TopFilter } from "./TopFilter";
import { LeftFilter } from "./LeftFilter";
import { ListActivity } from "./ListActivity";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import RangeSlider from "react-bootstrap-range-slider";
import { MobileHeader } from "./MobileHeader";
// import BASE_URLS from '../../baseUrl';
import { useFilter } from "../../Contexts/filterContext";
import { useCurr } from "../../Contexts/currContext";
import { useLocation } from "react-router-dom";
import { Skeleton } from "@mui/material"; // Import Skeleton from MUI

export const ActivityList = () => {
  const { price_value, handleFilters, locationName, act_value_list } =
    useFilter();
  const { selectedCurrency, currConvert } = useCurr();
  const location = useLocation();
  const activeTab = location.state && location.state.activeTab;
  const activities_id = location.state && location.state.activities_id;
  // const mainApiUrl = BASE_URLS.main;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [isLoading, setIsLoading] = useState(true);
  const [value, setValue] = useState(0);
  const handleChange = (changeEvent) => {
    setValue(changeEvent.target.value);
  };

  const { allData, catData, prodTags, actData, resultProd, actCount } =
    useFilter();

  useEffect(() => {
    // setLocationName(activeTab);
    const act_id = activities_id ? activities_id : "";
    act_id && handleFilters("", "", "", "", "", act_id, null, null, null);
    activeTab && handleFilters("", "", "", "", "", "", null, activeTab, null);
    setIsLoading(false);
  }, [activeTab]);

  useEffect(() => {
    // ActivityList();
  }, [act_value_list]);

  return (
    <div className="body-content">
      <Helmet>
        <title>List of Activities | Rathin Tourism LLC</title>
      </Helmet>
      <div className="breadcrumbs">
        <Container>
          <div className="d-flex justify-content-between align-items-center">
            <ol>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>List of Activities</li>
              {activeTab && <li>{activeTab}</li>}
            </ol>
          </div>
        </Container>
      </div>
      <MobileHeader allData={allData} actData={actData} />
      <section
        className="list overflow-visible"
        id="effects_wrapper"
      >
        <Container>
        {isLoading ?
          <div className="section-header pb-0">
            <Skeleton animation="wave" variant="text" width="22%" height={50} />
            <Skeleton animation="wave" variant="text" width="25%" height={30} />
          </div>
        : <div className="section-header pb-0 d-flex justify-content-between align-items-center">
            <p className="text-left">{actCount} activities for you</p>
            <p onClick={handleShow} className="mobile-block small">
              <i className="bi bi-sliders text-blue"></i>
            </p>
          </div>
        }
          <TopFilter actList={actData} />
          <Row className="gy-4 pt-3">
            <LeftFilter allData={allData} />
            <ListActivity resultProd={resultProd} />
          </Row>
        </Container>
      </section>
      <Modal show={show} onHide={handleClose} scrollable centered>
        <Modal.Header closeButton>
          <Modal.Title>Filter & Sort</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5> Activity Types</h5>
          <div className="search-list-icons">
            {actData.map((item, index) => (
              <div className="search-icons" key={index}>
                <input
                  type="checkbox"
                  className="btn-check"
                  id={"fa4" + item.act_id}
                  autoComplete="off"
                  onClick={(event) =>
                    handleFilters(
                      "",
                      "",
                      "",
                      "",
                      "",
                      item.act_id,
                      null,
                      null,
                      null
                    )
                  }
                  checked={act_value_list.includes(item.act_id)}
                />
                <label
                  className="btn btn-primary"
                  htmlFor={"fa4" + item.act_id}
                >
                  <i className={item.act_icon}></i>
                  {item.act_name}
                </label>
              </div>
            ))}
          </div>
          <hr />
          <div className="price-range">
            <h5>Price range</h5>
            <RangeSlider
              value={price_value}
              onChange={(event) =>
                handleFilters(
                  "",
                  event.target.value,
                  "",
                  "",
                  "",
                  "",
                  null,
                  null,
                  null
                )
              }
              className="custom-slider"
              min={0}
              max={5000}
            />
            <div className="d-flex justify-content-between pt-2">
              <span>{selectedCurrency} 0</span>
              <span>
                {selectedCurrency} {currConvert(price_value)}
              </span>
            </div>
          </div>
          <hr />
          <h5>Category</h5>
          {catData.map((item, index) => (
            <div className="form-check" key={index}>
              <input
                className="form-check-input"
                type="checkbox"
                name=""
                id={"fc1" + item.cat_id}
                value=""
                onClick={() =>
                  handleFilters(
                    item.cat_id,
                    "",
                    "",
                    "",
                    "",
                    "",
                    null,
                    null,
                    null
                  )
                }
              />
              <label className="form-check-label" htmlFor={"fc1" + item.cat_id}>
                {item.cat_name}
              </label>
            </div>
          ))}
          <hr />
          <h5>Sort by</h5>
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name="sort-by"
              id="recommended"
              onClick={(event) =>
                handleFilters("", "", "", "1", "", "", null, null, null)
              }
              value=""
            />
            <label className="form-check-label" htmlFor="recommended">
              Recommended
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name="sort-by"
              id="priceL-H-"
              onClick={(event) =>
                handleFilters("", "", "", "2", "", "", null, null, null)
              }
              value=""
            />
            <label className="form-check-label" htmlFor="priceL-H-">
              Price: Low to High
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name="sort-by"
              id="priceH-L-"
              onClick={(event) =>
                handleFilters("", "", "", "3", "", "", null, null, null)
              }
              value=""
            />
            <label className="form-check-label" htmlFor="priceH-L-">
              Price: High to Low
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="radio"
              name="sort-by"
              id="popularity-"
              onClick={(event) =>
                handleFilters("", "", "", "4", "", "", null, null, null)
              }
              value=""
            />
            <label className="form-check-label" htmlFor="popularity-">
              Popularity Score: High to Low
            </label>
          </div>
          <hr />
          <h5>Others</h5>
          {prodTags.map((item, index) => (
            <div className="form-check" key={index}>
              <input
                className="form-check-input"
                type="checkbox"
                name=""
                id={"fp1" + item.pro_tag_id}
                value=""
                onClick={(event) =>
                  handleFilters(
                    "",
                    "",
                    "",
                    "",
                    item.pro_tag_id,
                    "",
                    null,
                    null,
                    null
                  )
                }
              />
              <label
                className="form-check-label"
                htmlFor={"fp1" + item.pro_tag_id}
              >
                {item.pro_tag_name}
              </label>
            </div>
          ))}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
