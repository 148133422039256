import React, { useState, useEffect, useContext } from "react";
import BASE_URLS from "../baseUrl";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css'; // Don't forget to import the CSS
//import { useHistory } from 'react-router-dom';

const AuthContext = React.createContext();

// Hook method
export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider(props) {
  const mainApiUrl = BASE_URLS.main;
  //const webUrl = BASE_URLS.webUrl;
  //const history = useHistory();
  const [authUser, setAuthUser] = useState([]);

  const [isLoggedIn, setIsLoggedIn] = useState(
    localStorage.getItem("isLoggedIn") === "true"
  );

  const [isLoggedInWithGmail, setIsLoggedInWithGmail] = useState(
    localStorage.getItem("setIsLoggedInWithGmail") === "true"
  );

  const [authCurr, setauthCurr] = useState([]);

  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showSignupModal, setShowSignupModal] = useState(false);
  const [showSigninModal, setShowSigninModal] = useState(false);

  const openLoginModal = () => {
    setShowLoginModal(true);
  };

  const closeLoginModal = () => {
    setShowLoginModal(false);
  };

  const openSignupModal = () => {
    setShowSignupModal(true);
  };

  const closeSignupModal = () => {
    setShowSignupModal(false);
  };

  const openSigninModal = () => {
    setShowSigninModal(true);
  };
  const closeSigninModal = () => {
    setShowSigninModal(false);
  };

  useEffect(() => {
    setIsLoggedIn(localStorage.getItem('isLoggedIn') === 'true');
    setIsLoggedInWithGmail(localStorage.getItem('setIsLoggedInWithGmail') === 'true');
    return () => {
    };
  }, [setIsLoggedIn, setIsLoggedInWithGmail]);

  const handleSuccessfulLogin = () => {
    setIsLoggedIn(true); // Update the login status
    localStorage.setItem('isLoggedIn', 'true'); // Store the login status
  };

  const setSuccessfulGoogleLogin = () => {
    setIsLoggedInWithGmail(true);
    localStorage.setItem('setIsLoggedInWithGmail', 'true');
  }

  const handleGoogleLoginSuccess = async (response) => {

    const dataToSend = {
      email: response.email,
      family_name: response.family_name,
      given_name: response.given_name,
      // Add other necessary fields here
    };

    try {
      const response = await fetch(
        `${mainApiUrl}/google_login.php`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(dataToSend),
        }
      );
      const userData = await response.json();
      if (userData) {
        setAuthUser(userData.user_data);
        localStorage.setItem('setAuthUser', userData.user_data.usr_id);
      }
    } catch (error) {
      // Handle fetch or other errors here
      console.error(error);
    }

    handleSuccessfulLogin();
    setSuccessfulGoogleLogin();
    closeLoginModal();
    closeSignupModal();
    toast.success('Login successful!', {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const handleGoogleLogout = () => {
    setIsLoggedIn(false);
    setIsLoggedInWithGmail(false);
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('lastAccessTime');
    localStorage.removeItem('setIsLoggedInWithGmail');
    localStorage.removeItem('setAuthUser');
    // history.push(webUrl);
    toast.success('Logout successful!', {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const value = {
    authUser,
    setAuthUser,
    isLoggedIn,
    setIsLoggedIn,
    isLoggedInWithGmail,
    setIsLoggedInWithGmail,
    authCurr,
    setauthCurr,
    showSignupModal,
    closeSignupModal,
    handleSuccessfulLogin,
    setSuccessfulGoogleLogin,
    openLoginModal,
    openSigninModal,
    handleGoogleLoginSuccess,
    showLoginModal,
    closeLoginModal,
    openSignupModal,
    showSigninModal,
    closeSigninModal,
    handleGoogleLogout
  };

  // Check if lastAccessTime is not set in localStorage and set it
  useEffect(() => {
    const storedAccessTime = localStorage.getItem("lastAccessTime");
    if (!storedAccessTime) {
      localStorage.setItem("lastAccessTime", new Date().getTime().toString());
    }
  }, []);

  // Fetch user data from the API upon login or using the stored user ID
  useEffect(() => {
    const fetchData = async () => {
      const userId = parseInt(localStorage.getItem("setAuthUser"));
      // console.log(userId);
      if (!isNaN(userId) && userId > 0) {
        const dataToSend = {
          user_id: userId,
        };
        try {
          const response = await fetch(
            `${mainApiUrl}/update_user_details.php`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(dataToSend),
            }
          );
          const userData = await response.json();
          if (userData) {
            setAuthUser(userData.user_data);
          }
        } catch (error) {
          // Handle fetch or other errors here
          console.error(error);
        }
      }
    };

    fetchData();
  }, [mainApiUrl]);

  // Check if lastAccessTime is not set in localStorage and set it
 // Check if lastAccessTime is not set in localStorage and set it
useEffect(() => {
  const storedAccessTime = localStorage.getItem("lastAccessTime");
  const storedIsLoggedIn = localStorage.getItem("isLoggedIn");

  if (!storedAccessTime || (storedIsLoggedIn !== "true")) {
    // Clear localStorage data
    localStorage.clear();
    // Set lastAccessTime again
    localStorage.setItem("lastAccessTime", new Date().getTime().toString());
  } else {
    // Check for expiration and clear localStorage data if needed
    const currentTime = new Date().getTime();
    const expirationTime = parseInt(storedAccessTime, 10) + (24 * 60 * 60 * 1000); // 24 hours expiry time

    if (isNaN(expirationTime) || currentTime > expirationTime) {
      // Clear localStorage data
      localStorage.clear();
      // Set lastAccessTime again
      localStorage.setItem("lastAccessTime", currentTime.toString());
    }
  }
}, []);


  return (
    <AuthContext.Provider value={value}>
      {props.children}
    </AuthContext.Provider>
  );
}
