import React, { useState, useEffect } from "react";
import BASE_URLS from "../../baseUrl";
import { ReviewModal } from "./ReviewModal";
import { Badge } from "react-bootstrap";
import { Skeleton } from "@mui/material"; // Import Skeleton from MUI

export const CustomerReviews = ({ proData }) => {
    const mainApiUrl = BASE_URLS.main;
    const [proDataArr, setproDataArr] = useState([]);
    const [modalShow, setModalShow] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const getProduct = async () => {
            if (proData.products_id !== undefined) {
                const res = await fetch(
                    `${mainApiUrl}/get_cust_review.php?products_id=${proData.products_id}`
                );
                const getdata = await res.json();
                if (getdata) {
                    setproDataArr(getdata.review_list);
                    setIsLoading(false);
                }
            }
        };

        getProduct();
    }, [mainApiUrl, proData.products_id]);

    const truncateText = (text, maxLength) => {
        if (text.length > maxLength) {
            return text.substring(0, maxLength) + "...";
        }
        return text;
    };

    const displayItems = proDataArr.slice(0, 4);

    return (
        <>
            {isLoading ? ( // Render skeleton if loading
                <div className="blog-details" id="customer-reviews">
                    <Skeleton animation="wave" variant="text" width="30%" height={40} />
                    <div className="d-flex mb-2 justify-content-between star-rating align-items-center">
                        <Skeleton animation="wave" variant="rectangular" width={100} height={20} />
                        <div className="pb-4 pb-sm-2">
                            <Skeleton animation="wave" variant="rectangular" width={300} height={20} />
                        </div>
                    </div>
                    <div className="testimonials">
                        <div className="row gy-3">
                            {[...Array(2)].map((_, index) => (
                                <div className="col-md-6" key={index}>
                                    <div className="testimonial-item">
                                        <Skeleton animation="wave" variant="text" width="100%" height={120} />
                                        <Skeleton animation="wave" variant="text" width="20%" height={30} />
                                        <Skeleton animation="wave" variant="text" width="10%" height={30} />
                                        <Skeleton animation="wave" variant="text" width="18%" height={30} />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            ) : (
                displayItems.length > 0 && (
                    <div className="blog-details" id="customer-reviews">
                        <h3 className="mt-5 mb-4">Customer Reviews</h3>
                        <div className="d-flex mb-2 justify-content-between star-rating align-items-center">
                            {proData.rev_avg && (
                                <p className="mb-0 fs-3">
                                    <i className="fa fa-star"></i> {proData.rev_avg.toFixed(1)}{" "}
                                    <span className="fs-6 fw-normal">{proData.rev_id_cnt} Reviews</span>
                                </p>
                            )}
                            <div className="pb-4 pb-sm-2">
                                <Badge bg="light" text="dark">
                                    <i className="bi bi-shield-check text-success"></i> Reviews by customers who tried Rathin's activities
                                </Badge>
                            </div>
                        </div>
                        <div className="testimonials">
                            <div className="row gy-3">
                                {displayItems.map((item) => (
                                    <div className="col-md-6" key={item.review_id}>
                                        <div className="testimonial-item">
                                            <p>
                                                <i className="fa fa-quote-left quote-icon-left"></i>
                                                {truncateText(item.product_review, 100)}
                                                <i className="fa fa-quote-right quote-icon-right"></i>
                                            </p>
                                            <h3>{item.usr_fname}</h3>
                                            <h4>{item.usr_fname_type}</h4>
                                            <div className="stars">
                                                {[...Array(5)].map((_, index) => (
                                                    <i
                                                        key={index}
                                                        className={
                                                            index < item.product_rating
                                                                ? "bi bi-star-fill"
                                                                : "bi bi-star"
                                                        }
                                                    ></i>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        {displayItems.length >= 4 && (
                            <div className="col-md-12 text-center">
                                <button
                                    className="btn btn-primary mt-3"
                                    onClick={() => setModalShow(true)}
                                >
                                    See all reviews
                                </button>
                            </div>
                        )}
                        <ReviewModal
                            show={modalShow}
                            onHide={() => setModalShow(false)}
                            proDataArr={proDataArr}
                            proData={proData}
                        />
                    </div>
                )
            )}
        </>
    );
};
