import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import BASE_URLS from "../../baseUrl";

import { useWishlist } from "../../Contexts/WishlistContext";
import { WishlistButton } from "../WishList/WishlistButton";
import { useCurr } from "../../Contexts/currContext";

function ActivityTours({ id }) {
  const mainApiUrl = BASE_URLS.main;
  const webUrl = BASE_URLS.webUrl;
  const { wishlistItems, tourListWishlist, setTourListWishlist } =
    useWishlist();
  const { selectedCurrency, currConvert } = useCurr();
  const [proDataArr, setproDataArr] = useState([]);
  useEffect(() => {
    const getProduct = async () => {
      const res = await fetch(
        `${mainApiUrl}/get_products_also_like.php?product_slug=` + id
      );
      const getdata = await res.json();
      setproDataArr(getdata.prod_list);
    };

    getProduct();
  }, [mainApiUrl, id]);

  useEffect(() => {
    if (wishlistItems && proDataArr?.length > 0) {
      const initialWishlistStatus = {};
      proDataArr?.forEach((product) => {
        initialWishlistStatus[product.product_id] = wishlistItems.some(
          (item) => item.product_id === product.product_id
        );
      });
      setTourListWishlist(initialWishlistStatus);
    }
  }, [wishlistItems, proDataArr]);

  return (
    <section className="list">
      <Container>
        <div className="section-header">
          <p>
            You might <span>also like</span>
          </p>
        </div>
        <Row className="gy-4">
          {proDataArr.map((item, index) => (
            <Col lg={3} md={4} sm={6} xs={6} key={index}>
              <div className="wsk-cp-product">
                <Link to={`${webUrl}activity/${item.product_slug}`}>
                  <div className="wsk-cp-img">
                    <img
                      loading="lazy"
                      src={item.product_image}
                      alt={item.product_name}
                      className="img-fluid"
                      width="100%" 
                      height="auto"
                    />
                    <div className="add-cart">
                      <WishlistButton
                        heartToggle={tourListWishlist[item.product_id]}
                        product={item.product_id}
                      />
                    </div>
                  </div>
                  <div className="wsk-cp-text">
                    <div className="title-product">
                      <h3>{item.product_name}</h3>
                    </div>
                    <div className="description-prod">
                    {(item.availablity) == "Not Available" ? (
                          <p className="text-danger">{item.availablity}</p>
                        ) : <p className="text-success">{item.availablity}</p>}
                    </div>
                    <div className="card-footer">
                      <div className="wcf-left">
                        from
                        <span className="price">
                          <span className="currency">{selectedCurrency}</span>{" "}
                          {currConvert(item.product_price_starts)}
                        </span>
                      </div>
                      <div className="wcf-right">
                        <p>
                          <i className="fa fa-star"></i> {item.product_rating}
                        </p>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
}
export default ActivityTours;
