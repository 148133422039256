import React from "react";
import { CardElement, PaymentElement, useElements } from "@stripe/react-stripe-js";

const paymentElementOptions = {
  layout: "tabs"
}
const StripePayment = () => {
  return <PaymentElement id="payment-element" options={paymentElementOptions}/>;

  // return <CardElement id="card-element" className="card-element" />;
};

export default StripePayment;
