import React, { useState, useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import BASE_URLS from '../../baseUrl';
import { Skeleton } from "@mui/material"; // Import Skeleton from MUI

export const AboutContent = () => {

    const webUrl = BASE_URLS.main;
    const [about_us_Data, set_about_us_Data] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await fetch(`${webUrl}/get_footer_useful_links.php?mode=about_us`);
                if (!res.ok) {
                    throw new Error('Failed to fetch data');
                }
                const getdata = await res.json();
                set_about_us_Data(getdata.data);
            } catch (error) {
                console.error('Error fetching data:', error);
                // Handle error state here, such as showing an error message to the user
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [webUrl]);

    return (
        <section className='about'>
            <Container>
                {loading ?
                    <Row className='gy-3 g-5'>
                        <Col lg={6} md={12} sm={12} xs={12}>
                            <Skeleton animation="wave" variant="rounded" width="100%" height={760} />
                        </Col>
                        <Col lg={6} md={12} sm={12} xs={12}>
                            <Skeleton animation="wave" variant="text" width="40%" height={40} className="text-center mb-3" />
                            {Array.from({ length: 30 }).map((_, index) => (
                                <Skeleton key={index} animation="wave" variant="text" width="100%" height={20} />
                            ))}
                        </Col>
                    </Row>
                    :
                    <Row className='gy-3 g-5'>
                        <Col lg={6} md={12} sm={12} xs={12}>
                            <img src={about_us_Data.about_us_image} width="100%" height="auto" alt="About Us" className='img-fluid rounded' loading="lazy" />
                        </Col>
                        <Col lg={6} md={12} sm={12} xs={12}>
                            <div className="section-header">
                                <p>About <span>Us</span></p>
                            </div>
                            <span dangerouslySetInnerHTML={{ __html: about_us_Data.about_us_content }}></span>
                        </Col>
                    </Row>
                }
            </Container>
        </section>
    )
}
