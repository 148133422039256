import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAuth } from '../Contexts/AuthContext';
import BASE_URLS from '../baseUrl';

function SigninModal({ onHide, showSignin, openLoginModal }) {
  const mainApiUrl = BASE_URLS.main;
  const webUrl = BASE_URLS.webUrl;

  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordVisible1, setPasswordVisible1] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [email, setEmail] = useState('');

  const {
    setIsLoggedIn, setAuthUser
  } = useAuth();

  useEffect(() => {
    setIsLoggedIn(localStorage.getItem('isLoggedIn') === 'true');
  }, [setIsLoggedIn]);

  const handleSuccessfulLogin = () => {
    setIsLoggedIn(true); // Update the login status
    localStorage.setItem('isLoggedIn', 'true'); // Store the login status
    
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const togglePasswordVisibility1 = () => {
    setPasswordVisible1(!passwordVisible1);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const isPasswordValid = () => {
    // Minimum 8 characters, maximum 15 characters, at least one special character
    const passwordRegex = /^(?=.*[A-Za-z0-9])(?=.*[^A-Za-z0-9]).{8,15}$/;
    return passwordRegex.test(password);
  };

  const isPasswordsMatching = () => {
    return password === confirmPassword;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isPasswordValid()) {
      setErrorMessage('Password must be between 8 and 15 characters and contain at least one special character.');
    } else if (!isPasswordsMatching()) {
      setErrorMessage('Passwords do not match.');
    } else {
      // Password is valid, handle form submission here
      fetch(`${mainApiUrl}/user_registration.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: email,
          newPassword: password,
          confirmPassword: confirmPassword,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.status === 'Success') {
              // Registration was successful
              handleSuccessfulLogin();
              //const { user_id } = data; // Get the user ID from the response
             // localStorage.setItem('authUser', user_id); // Store the user ID in local storage
              toast.success('Successfully registered!', {
                  position: toast.POSITION.TOP_CENTER,
              });
              onHide(); // Close the modal
              setAuthUser(data);
              //localStorage.setItem('setAuthUser', JSON.stringify(userData));
              localStorage.setItem('setAuthUser', data.usr_id);
              
              // You can also handle other actions on success
          } else if (data.status === 'Failed') {
              // Registration failed due to existing user log name
              toast.error(data.message, {
                  position: toast.POSITION.TOP_CENTER,
              });
          }
      })
        .catch((error) => {
          // Handle network errors or API request failures
          console.error('Error:', error);
          toast.error('An error occurred while registering.', {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    }
  };

  const handleModalClose = () => {
    onHide(); // Close the modal
    setErrorMessage(''); // Reset error message when the modal is closed
  };

  return (
    <>
      <Modal show={showSignin} onHide={handleModalClose} size="md" centered >
        <Modal.Header closeButton className='border-bottom-0'></Modal.Header>
        <Modal.Body className="text-center p-5 pt-0">
          <h4 className="font-weight-bold">Sign up</h4>
          <p>to unlock the full experience</p>
          <form action="" method="post" className="Login-form" onSubmit={handleSubmit}>
            <div className="form-group mt-3">
              <input
                type="email"
                name="email"
                className="form-control"
                placeholder="Email"
                value={email} // Use the email state variable
                onChange={handleEmailChange} // Handle input changes
                required
              />
            </div>
            <div className="form-group mt-3 position-relative d-flex align-items-center">
              <input
                type={passwordVisible ? 'text' : 'password'}
                className="form-control"
                name="newPassword"
                placeholder="Enter password"
                minLength={8}
                maxLength={15}
                required
                onChange={handlePasswordChange}
                autoComplete=''
              />
              <span
                className="svg-icon svg-icon-2 position-absolute mx-4 right-0"
                onClick={togglePasswordVisibility}
              >
                <i
                  className={`text-blue bi bi-${
                    passwordVisible ? 'eye-slash-fill' : 'eye-fill'
                  }`}
                ></i>
              </span>
            </div>
            <div className="form-group mt-3 position-relative d-flex align-items-center">
              <input
                type={passwordVisible1 ? 'text' : 'password'}
                className="form-control"
                name="confirmPassword"
                placeholder="Confirm password"
                required
                onChange={handleConfirmPasswordChange}
                autoComplete=''
              />
              <span
                className="svg-icon svg-icon-2 position-absolute mx-4 right-0"
                onClick={togglePasswordVisibility1}
              >
                <i
                  className={`text-blue bi bi-${
                    passwordVisible1 ? 'eye-slash-fill' : 'eye-fill'
                  }`}
                ></i>
              </span>
            </div>
            <p className="pt-2 small text-muted">Must contain at least 8 characters</p>
            <div className="form-group form-check mt-3">
              <input
                type="checkbox"
                className="form-check-input"
                id="terms-check"
              />
              <label
                className="form-check-label small text-left"
                htmlFor="terms-check"
              >
                keep me updated on exclusive promotions and deals, product
                updates, and travel inspiration, including offers from our
                partners
              </label>
            </div>
            <div className="form-group mt-5 text-center">
              <Button type="submit" name='sign_in_modal' variant="primary">
                Create account
              </Button>
            </div>
          </form>
          {errorMessage && (
            <div className="text-danger mt-3">{errorMessage}</div>
          )}
          <p className="pt-3 small">
            By signing up, you agree to Rathin's{' '}
            <Link to={`${webUrl}terms-of-use`}>Terms of Use</Link> and
            for your personal data to be processed in accordance with our{' '}
            <Link to={`${webUrl}privacy-policy`}>Privacy Policy</Link>.
          </p>
          <h6 className="font-weight-bold pt-4">
            Don’t have an account?{' '}
            <Link
              onClick={() => {
                onHide();
                openLoginModal();
              }}
            >
              Log in
            </Link>
          </h6>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default SigninModal;
