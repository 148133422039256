import React, { useState, useEffect } from 'react';
import BASE_URLS from '../../baseUrl';
import Tours from './TourCarousal';
import Highlights from './Highlights';
import Destinations from './Destination';
import ToursTab from './ToursTab';
import HomeBanner from './HomeBanner';
import BookNow from './BookNow';
import { CustomerSays } from './CustomerSays';
import Coupon from './Coupon';
import { Container } from 'react-bootstrap';
import ProductViewOne from './ProductsViewOne';
import ProductViewTwo from './ProductsViewTwo';
import ProductViewThree from './ProductsViewThree';
import ProductViewFour from './ProductsViewFour';
import ProductViewFive from './ProductsViewFive';

function Home() {
  const mainApiUrl = BASE_URLS.main;
  const [couponDetails, setCouponDetails] = useState(null);

  useEffect(() => {
    fetchCouponDetails();
  }, []);

  const fetchCouponDetails = async () => {
    try {
      const response = await fetch(`${mainApiUrl}/get_coupon.php`);
      if (response.ok) {
        const data = await response.json();
        setCouponDetails(data);
      } else {
        throw new Error('Failed to fetch coupon details');
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className='body-content'>
      <HomeBanner />
      <ProductViewOne />
      <Destinations />
      <ProductViewTwo />
      <BookNow />
      {couponDetails && couponDetails.coup_id > 0 && (
        <section className='pt-0'>
          <Container>
            <Coupon />
          </Container>
        </section>
      )}
      <ProductViewThree />
      <ToursTab />
      <CustomerSays />
      <ProductViewFour />
      <Highlights />
      <ProductViewFive />
      <Tours />
    </div>
  );
}

export default Home;
