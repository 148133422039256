import React, { useState, useEffect } from "react";
import { EmptyBookings } from './EmptyBookings';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { BookingList } from './BookingList';
import BASE_URLS from "../../baseUrl";
import { useCurr } from "../../Contexts/currContext";
import { PastBookingList } from "./PastBookingList";
import { Skeleton } from "@mui/material"; // Import Skeleton from MUI
function Bookings() {

    const mainApiUrl = BASE_URLS.main;
    const webUrl = BASE_URLS.webUrl;
    const usr_id = localStorage.getItem("setAuthUser");
    const { selectedCurrency, currConvert } = useCurr();
    const [orderComplete, setOrderComplete] = useState([]);
    const [passedOrder, setPassedOrder] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
      const getOrderList = async () => {
          try {
              const res = await fetch(
                  `${mainApiUrl}/get_booking_complete.php?user_id=` + usr_id
              );
              const getdata = await res.json();

              if (getdata && getdata.order_list_upcoming) {
                  setOrderComplete(getdata.order_list_upcoming);
              }

              if (getdata && getdata.order_list) {
                setPassedOrder(getdata.order_list);
              }

          } catch (error) {
              console.error('Error fetching data:', error);
          } finally {
            setIsLoading(false);
          }
      };
      getOrderList();
  }, [mainApiUrl, usr_id]);


  return (
    <>
        <h4 className='font-weight-bold'>Bookings</h4>
        <Tabs
        defaultActiveKey="upcoming"
        id="uncontrolled-tab-example"
        className="mb-3 mt-3"
        >
            <Tab eventKey="upcoming" title="Upcoming">
            {isLoading
              ? Array.from({ length: 2 }).map((_, index) => (
                <Skeleton key={index} animation="wave" variant="rounded" width="100%" height={180} className="mb-2" />
              ))
            : orderComplete.length > 0 ? (
                <BookingList 
                  orderComplete={orderComplete}
                  webUrl={webUrl}
                  mainApiUrl={mainApiUrl}
                  selectedCurrency={selectedCurrency}
                  currConvert={currConvert}
                />
              ) : (
                <>
                  <h4 className='font-weight-bold text-center'>You have no upcoming bookings</h4>
                  <EmptyBookings />
                </>
              )}
            </Tab>
            <Tab eventKey="cancel" title="Past & cancelled">
            {isLoading
              ? Array.from({ length: 2 }).map((_, index) => (
                <Skeleton key={index} animation="wave" variant="rounded" width="100%" height={180} className="mb-2" />
              ))
            : passedOrder.length > 0 ? (
                <PastBookingList 
                  passedOrder={passedOrder}
                  webUrl={webUrl}
                  mainApiUrl={mainApiUrl}
                  selectedCurrency={selectedCurrency}
                  currConvert={currConvert}
                />
                ) : (
                  <>
                    <h4 className='font-weight-bold text-center'>You have no past bookings</h4>
                    <EmptyBookings />
                  </>
              )}
            </Tab>
        </Tabs>
       
    </>
  );
}

export default Bookings;
