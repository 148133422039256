import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { SelectPreference } from "./selectPreference";
import { TimeSlot } from "./TimeSlot";
import BASE_URLS from "../../baseUrl";

function formatDate(date) {
    const options = { weekday: "short", day: "2-digit", month: "short", year: "numeric"  };
    return new Date(date).toLocaleDateString("en-US", options);
}

export const SelectSpec = ({
    item,
    proData,
    selectedDate,
    showSpec,
    openCalendarModal,
    hideSpecModal,
    handleEditDate,
    setSelectedItem,
    closeSpecModal,
}) => {
    const [newJoinedValue, setNewJoinedValue] = useState('');
    const [selectedTimeSlot, setSelectedTimeSlot] = useState('');

    useEffect(() => {
        if (item) {
            const getTicketDets = async () => {
                const payload = {
                    item: item,
                    selectedDate: formatDate(selectedDate),
                    from: 'SpecModal',
                    newJoinedValue: newJoinedValue,
                };

                try {
                    const response = await fetch(
                        `${mainApiUrl}/get_products_tickets_data.php`,
                        {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                            },
                            body: JSON.stringify(payload),
                        }
                    );

                    if (response.ok) {
                        const data = await response.json();
                        if (data.arr_spec_list) {
                            setdisplayTickets(data.arr_spec_list);
                            setdisplayTicketsData(data);
                            setSelectedTicket(data.partsSpValue)
                            setSelectedTimeSlot(data.arr_sess_def_time);
                        }
                    } else {
                    }
                } catch (error) {
                    console.error("Error:", error);
                }
            };
            getTicketDets();
        }
    }, [item, selectedDate]);

    const [displayTickets, setdisplayTickets] = useState([]);
    const [displayTicketsData, setdisplayTicketsData] = useState([]);

    const mainApiUrl = BASE_URLS.main;

    const [showPreferenceModal, setShowPreferenceModal] = useState(false);
    const [selectedTicket, setSelectedTicket] = useState(displayTicketsData.partsSpValue);
    const closePreferenceModal = () => {
        setShowPreferenceModal(false);
    };
    const hidePreferenceModal = () => {
        setShowPreferenceModal(false);
    };
    const openPreferenceModal = () => {
        setShowPreferenceModal(true);
    };
    const TicketSelected = (selTick, selTick2) => {
        // setSelectedTicket(displayTicketsData.partsSpValue);
    }
    const handleSpecNext = () => {
        // Create an array to store selected values
        const selectedValues = [];
        // Loop through displayTickets to gather selected values
        displayTickets.forEach((item) => {
            const selectedRadio = document.querySelector(`input[name="${item.specification_name}"]:checked`);
            if (selectedRadio) {
                selectedValues.push(selectedRadio.value);
            }
        });

        // Join selected values with "/"
        var joinedValues = selectedValues.join('/');
        // Log the result
        if (selectedTicket > 0 && selectedValues.length > 0) {
            joinedValues = joinedValues + '/' + selectedTicket;
        }

        setNewJoinedValue(joinedValues);
        closeSpecModal(false);
        setSelectedItem(item); // Set the selected item in state
        openPreferenceModal(item, selectedDate, joinedValues); // Pass item and selectedDate to openPreferenceModal
    };

    return (
        <>
            <Modal
                show={showSpec}
                onHide={hideSpecModal}
                size="lg"
                centered
                scrollable
            >
                <Modal.Header closeButton>
                    <Modal.Title>Select your preferences</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {proData && item ? (
                        <div className="alert alert-info" role="alert">
                            <h5>{proData.product_name}</h5>
                            <p>{item.actTitle}</p>
                            {proData.tick_type !== 3 ? (
                                <div className="d-flex justify-content-between align-items-center pt-2">
                                    {selectedDate && (
                                        <p className="date font-weight-bold">
                                            {formatDate(selectedDate ? selectedDate.toString() : new Date().toString())}
                                        </p>
                                    )}
                                    <p
                                        className="text-blue font-weight-bold"
                                        onClick={() => {
                                            handleEditDate();
                                            openCalendarModal(item);
                                            hideSpecModal();
                                        }}
                                    >
                                        <i className="bi bi-pencil"></i> Edit date
                                    </p>
                                </div>
                            ) : null}
                        </div>
                    ) : (
                        <p>Loading...</p>
                    )}

                    <TimeSlot arrSessList={displayTicketsData.arr_sess_list} setSelectedTimeSlot={setSelectedTimeSlot} selectedTimeSlot={selectedTimeSlot}/>

                    <div className="qty-body mt-3">

                        {displayTickets &&
                            Array.isArray(displayTickets) &&
                            displayTickets.map((item, index) => (
                                <div className="region pb-4" key={index}>
                                    <h6>Choose Your {item.specification_name}</h6>
                                    <div className="quantity-details d-flex gap-2">
                                        {item.specification_value_names.map((filteredItem, index2) => (
                                            <span key={index2}>
                                                <input
                                                    type="radio"
                                                    className="btn-check"
                                                    name={item.specification_name_radio}
                                                    id={filteredItem.partsSpValue}
                                                    autoComplete="off"
                                                    onClick={() => TicketSelected(displayTicketsData.partsSpValue, filteredItem.partsSpecValue)}
                                                    defaultChecked={true}
                                                    value={filteredItem.partsSpecValue}
                                                />
                                                <label className="btn btn-secondary" htmlFor={filteredItem.partsSpValue}>
                                                    <span>{filteredItem.actTitle}</span>
                                                </label>
                                            </span>
                                        ))}
                                    </div>
                                </div>
                            ))}

                    </div>
                </Modal.Body>
                <Modal.Footer className="justify-content-between">
                    <div className="d-grid">

                    </div>
                    {/* {displayTickets.every((item) => item.quantity === 0) ? (
                        <Button variant="primary" disabled>
                            Next
                        </Button>
                    ) : ( */}
                        <Button variant="primary" onClick={handleSpecNext}>
                            Next
                        </Button>
                    {/* )} */}
                </Modal.Footer>
            </Modal>

            <SelectPreference
                proData={proData}
                item={item}
                selectedDate={selectedDate}
                showPreference={showPreferenceModal}
                onHide={closePreferenceModal}
                openCalendarModal={openCalendarModal}
                handleEditDate={handleEditDate}
                hidePreferenceModal={hidePreferenceModal}
                newJoinedValue={newJoinedValue}
                selectedTimeSlot={selectedTimeSlot}
            />
        </>
    );
};

export default SelectSpec;
