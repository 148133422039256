import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useParams } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import Accordion from 'react-bootstrap/Accordion';
import { AskFaq } from './AskFaq';
import Skeleton from '@mui/material/Skeleton';
import BASE_URLS from '../../baseUrl';

export const Articles = () => {
  const mainApiUrl = BASE_URLS.main;
  const webUrl = BASE_URLS.webUrl;
  const { cat_id } = useParams();
  const [activeKey, setActiveKey] = useState(cat_id || '1');
  const [searchQuery, setSearchQuery] = useState('');
  const [categories, setCategories] = useState([]);
  const [faqData, setFaqData] = useState([]);
  const [filteredContent, setFilteredContent] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(`${mainApiUrl}/get_faq_category.php`);
        const data = await response.json();
        setCategories(data.cat_list);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    const fetchFaqData = async () => {
      try {
        const response = await fetch(`${mainApiUrl}/get_faq_list.php`);
        const data = await response.json();
        setFaqData(data.prod_list);
      } catch (error) {
        console.error('Error fetching FAQ data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
    fetchFaqData();
  }, [mainApiUrl]);

  useEffect(() => {
    if (!searchQuery) {
      setFilteredContent(faqData);
    } else {
      const filtered = faqData.filter((item) =>
        item.faq_question.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredContent(filtered);
    }
  }, [searchQuery, faqData]);

  return (
    <div className='body-content'>
      <Helmet>
        <title>Articles | Rathin Tourism LLC</title>
      </Helmet>
      <div className="breadcrumbs">
        <Container>
          <div className="d-flex justify-content-between align-items-center">
            <ol>
              <li>
                <Link to={webUrl}>Home</Link>
              </li>
              <li>Articles</li>
            </ol>
          </div>
        </Container>
      </div>
      <section className='faq overflow-visible'>
        <Container>
          <Row className='gy-3 pb-5 checkout align-items-center'>
            <Col sm={8}>
              <h5>Articles in this section</h5>
            </Col>
            <Col sm={4}>
              <form>
                <div className="position-relative d-flex align-items-center">
                  <span className="svg-icon svg-icon-2 position-absolute mx-4">
                    <i className="bi bi-search"></i>
                  </span>
                  <input
                    type="search"
                    className="form-control ps-12"
                    placeholder="Search within active one"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                </div>
              </form>
            </Col>
          </Row>
          <Tab.Container id="left-tabs-example" activeKey={activeKey} onSelect={(key) => setActiveKey(key)}>
            <Row className='gy-3 gx-5'>
              <Col sm={3}>
                <div className='sidebar sticky-top'>
                {loading
                ? Array.from({ length: 6 }).map((_, index) => (
                    <Skeleton key={index} animation="wave" variant="rounded" width="100%" height={40} className="mb-1" />
                ))
                :
                  <Nav variant="pills" className="flex-column">
                    {categories.map((category) => (
                      <Nav.Item key={category.cat_id}>
                        <Nav.Link eventKey={category.cat_slug.toString()}>
                          {category.cat_name}
                        </Nav.Link>
                      </Nav.Item>
                    ))}
                  </Nav>
                }
                </div>
              </Col>
              <Col sm={9}>
                {loading
                  ? Array.from({ length: 10 }).map((_, index) => (
                      <Skeleton key={index} animation="wave" variant="rounded" width="100%" height={60} className="mb-1" />
                  ))
                  :
                <Tab.Content>
                  {categories.map((category) => (
                    <Tab.Pane key={category.cat_id} eventKey={category.cat_slug.toString()}>
                      {filteredContent
                        .filter((faq) => faq.cat_id === category.cat_id)
                        .length === 0 ? (
                        <p>No content available for this category</p>
                      ) : (
                        <Accordion defaultActiveKey="0">
                          {filteredContent
                            .filter((faq) => faq.cat_id === category.cat_id)
                            .map((item) => (
                              <Accordion.Item key={item.faq_id} eventKey={item.faq_id.toString()}>
                                <Accordion.Header>{item.faq_question}</Accordion.Header>
                                <Accordion.Body>
                                  <p>{item.faq_ans}</p>
                                  {item.faq_ans_photo && item.faq_ans_photo !== ""  && item.faq_ans_photo !== null ? (
                                  <img src={item.faq_ans_photo} className='img-fluid' alt={item.cat_id} loading="lazy" width="100%" 
                                  height="auto" />
                                  ) : null}
                                </Accordion.Body>
                              </Accordion.Item>
                            ))}
                        </Accordion>
                      )}
                    </Tab.Pane>
                  ))}
                </Tab.Content>
                }
              </Col>
            </Row>
          </Tab.Container>
        </Container>
      </section>
      <AskFaq />
    </div>
  );
};
