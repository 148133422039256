import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useCurr } from "../../Contexts/currContext";

export const ActivityListDetailModal = ({ show, onHide, proData, actItem, openCalendarModal, selectedDate, item, handleSelect, formatDate }) => {

  const handleClose = (actItem) => {
    onHide(); // Close the current modal
    openCalendarModal(actItem); // Open the other modal
  };

  const handleClick = (actItem) => {
    onHide(); // Close the current modal
    handleSelect(actItem);
  };


  const { selectedCurrency, currConvert } = useCurr();
  return (
    <Modal show={show} onHide={onHide} size="lg" centered scrollable>
      <Modal.Header closeButton>
        <Modal.Title>Activity details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="alert alert-info" role="alert">
          <h5>{proData.product_name}</h5>
          <p>{actItem ? actItem.actTitle : ''}</p>
        </div>
        <p><span dangerouslySetInnerHTML={{ __html: actItem ? actItem.actDesc : '' }}></span></p>
      </Modal.Body>
      <Modal.Footer className="justify-content-between">
        <div className="d-grid">
          <div>
            <h5>Total</h5>
          </div>
          <div className="d-grid">
            <h5 className="mb-0">
              {selectedCurrency} {actItem && actItem.actAmount ? currConvert(actItem.actAmount) : ''}
            </h5>
          </div>
        </div>
        {/* <Button variant="primary" onClick={handleClose}>
          Select
        </Button> */}

        {selectedDate || proData.tick_type === 3 ? (
          <div className="card-footer-right">
            <Button
              variant="primary"
              onClick={() => handleClick(actItem)}
            >
              Select
            </Button>
          </div>
        ) : (
          <div className="card-footer-right">
            <span>
              <small>
                <i className="bi bi-calendar-check"></i> Next
                {actItem ? (
                  <>
                  availability: { formatDate(actItem.actAvailDate)}
                  </>
                  ) : null}
              </small>
            </span>
            <Button
              variant="primary"
              onClick={() => handleClose(actItem)}
            >
              Check availability
            </Button>
          </div>
        )}

      </Modal.Footer>
    </Modal>
  );
};
