import React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import BASE_URLS from '../../baseUrl';

function SearchResult({ searchQuery, className, setSearchQuery }) {

    const mainApiUrl = BASE_URLS.main;
    const webUrl = BASE_URLS.webUrl;

    const [result, setTotProd] = useState([]);
    const [loading, setLoading] = useState(true); // State to manage loading status

    useEffect(() => {
        const getProducts = async () => {
            try {
                const res = await fetch(`${mainApiUrl}/get_products_search.php`);
                const getdata = await res.json();
                if (getdata.prod_list) {
                    setTotProd(getdata.prod_list);
                }
            } catch (error) {
                console.error('Error fetching products:', error);
            } finally {
                setLoading(false); // Set loading to false after data retrieval
            }
        }
        getProducts();
    }, [mainApiUrl]);

    const navigate = useNavigate();
    const handleResultClick = (link) => {
        navigate(`${webUrl}${link}`);
        setSearchQuery("");
    };

    const filteredResults = result.filter((results) => {
        const normalizedQuery = searchQuery.toLowerCase();
        return (
            results.title.toLowerCase().includes(normalizedQuery) ||
            results.sub.toLowerCase().includes(normalizedQuery)
        );
    });

    // Store the filtered results in a state variable
    const [displayedResults, setDisplayedResults] = useState(filteredResults.slice(0, 3));

    useEffect(() => {
        if (searchQuery.trim() === '') {
            // If the search input is empty, reset displayedResults to the initial limited results
            setDisplayedResults(filteredResults.slice(0, 0));
        } else {
            // If there is a search query, set displayedResults to the filtered results
            setDisplayedResults(filteredResults);
        }
    }, [searchQuery]);

    return (
        <div className={`search-result position-absolute w-100 ${className}`} id="search-result-header">
            {loading ? ( // Render loader if loading is true
                <div className="loader"> &nbsp;&nbsp;&nbsp;loading... </div>
            ) : filteredResults.length > 0 ? (
                <ul>
                    {displayedResults.map((result) => (
                        <li key={result.id} onClick={() => handleResultClick(result.link)}>
                            <div className="data-list align-items-center gap-2">
                                <div className="loc-img">
                                    <img loading="lazy" src={result.image} alt={result.title} width="100%"
                                        height="auto" />
                                </div>
                                <div className="loc-desc">
                                    <div className="loc-det">
                                        <h6 className="mb-0">{result.title}</h6>
                                        <p className="mb-0"><i className="bi bi-geo-alt"></i>{result.sub}</p>
                                    </div>
                                    <div className="loc-link">
                                        <i className="bi bi-arrow-right"></i>
                                    </div>
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
            ) : searchQuery.length > 0 ? (
                <p className='text-black p-2 mb-0'>No results found for "{searchQuery}"</p>
            ) : (
                null
            )}
        </div>
    );
}

export default SearchResult;
