import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Badge } from "react-bootstrap";

export const ReviewModal = ({ proData, proDataArr, show, onHide }) => {
  const formatReviewDate = (dateString) => {
    const options = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour12: false,
    };

    const formattedDate = new Date(dateString).toLocaleString('en-US', options);
    return formattedDate;
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Customer reviews
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex mb-2 justify-content-between star-rating align-items-center">
            {proData.rev_avg && (
            <p className="mb-0 fs-3 fw-bold"><i className="fa fa-star"></i> {proData.rev_avg.toFixed(1)}{" "} <span className="fs-6 fw-normal">{proData.rev_id_cnt} Reviews</span></p>
            )}
            <div className="pb-4 pb-sm-2">
                <Badge bg="light" text="dark">
                    <i className="bi bi-shield-check text-success"></i> Reviews by customers who tried Rathin's activities
                </Badge>
            </div>
        </div>
        <div className='testimonials '>
          {proDataArr.map((item) => (
            <div className='testimonial-item mb-4' key={item.review_id}>
              <p>
                {item.product_review}
              </p>
              <h3>{item.usr_fname}</h3>
              <h4>{formatReviewDate(item.created_dtm)}</h4>
              <div className="stars">
                {[...Array(5)].map((_, index) => (
                  <i
                    key={index}
                    className={
                      index < item.product_rating
                        ? "bi bi-star-fill"
                        : "bi bi-star"
                    }
                  ></i>
                ))}
              </div>
            </div>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};
