import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import Skeleton from '@mui/material/Skeleton';
import BASE_URLS from '../../baseUrl';

export const Faq = () => {
  const mainApiUrl = BASE_URLS.main;
  const [faq, setFaq] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getFaq = async () => {
      try {
        const res = await fetch(`${mainApiUrl}/get_faq_list.php`);
        const getdata = await res.json();
        setFaq(getdata.prod_list.slice(0, 10)); // Limit to 10 FAQs
      } catch (error) {
        console.error('Error fetching FAQs:', error);
      } finally {
        setLoading(false);
      }
    };
    getFaq();
  }, [mainApiUrl]);

  return (
    <section>
      <Container>
        <Row>
          <Col>
            <div className="section-header">
              <p>
                Popular <span>FAQs</span>
              </p>
            </div>
            {loading ? (
              // Display Skeleton while loading
              Array.from({ length: 4 }).map((_, index) => (
                <Skeleton key={index} varient="rounded" width="100%" height={120} animation="wave"/>
              ))
            ) : (
              <>
                {faq.length === 0 ? (
                  <h5 className='text-center'>No FAQs available at the moment..!</h5>
                ) : (
                  <Accordion defaultActiveKey="0">
                    {faq.map((item, index) => (
                      <Accordion.Item key={index} eventKey={index.toString()}>
                        <Accordion.Header>{item.faq_question}</Accordion.Header>
                        <Accordion.Body>
                          <p>{item.faq_ans}</p>
                          {item.faq_ans_photo && item.faq_ans_photo !== "" && item.faq_ans_photo !== null ? (
                            <img src={item.faq_ans_photo} className='img-fluid' alt={item.cat_id} loading="lazy" width="100%" 
                            height="auto"/>
                          ) : null}
                        </Accordion.Body>
                      </Accordion.Item>
                    ))}
                  </Accordion>
                )}
              </>
            )}
          </Col>
        </Row>
      </Container>
    </section>
  );
};
