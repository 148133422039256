import React, { useState, useEffect } from 'react';
import { Skeleton } from '@mui/material'; // Import Skeleton from MUI
import BASE_URLS from '../../baseUrl';

export const Location = ({ proData }) => {
  const [isLoading, setLoadingProdAdd] = useState(true);
  const [resForAdd, setformatAddr] = useState('');
  const [resMap, setMap] = useState('');
  const mainApiUrl = BASE_URLS.main;

  useEffect(() => {
    const getCurrAddr = async () => {
        if (proData.product_slug) {
            try {
                const res = await fetch(`${mainApiUrl}/get_product_maps.php?product_slug=${proData.product_slug}`);
                const data = await res.json();
                setformatAddr(data.formatted_address);
                setMap(data.googleurl);
            } catch (error) {
                console.error('Error fetching contact details:', error);
            } finally {
                setLoadingProdAdd(false);
            }
        }
    };
    getCurrAddr();
}, [mainApiUrl, proData]);

  return (
    <div className="location">
      
      {isLoading ? ( // Render skeleton if loading
        <>
          <Skeleton animation="wave" variant="text" width="20%" height={30} className="mb-2" />
          <Skeleton animation="wave" variant="text" width="20%" height={20} />
          <Skeleton animation="wave" variant="text" width="50%" height={20} className="mb-2" />
          <Skeleton animation="wave" variant="rounded" width="100%" height={400} />
        </>
      ) : (
        <>
          <h3 className="mt-5 mb-4">Location</h3>
          <p className="mb-1 font-weight-bold">Address</p>
          <p>{proData.product_name} {resForAdd}</p>
          <span dangerouslySetInnerHTML={{ __html: resMap }}></span>
        </>
      )}
    </div>
  );
};
