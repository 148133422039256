import React from 'react'
import {Helmet} from "react-helmet";
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import { ContactMap } from './ContactMap';
import { ContactDetails } from './ContactDetails';
import BASE_URLS from '../../baseUrl';

export const ContactUs = () => {
  const webUrl = BASE_URLS.webUrl;
  return (
    <div className='body-content'>
        <Helmet>
            <title>Contact Us | Rathin Tourism LLC</title>
        </Helmet>
        <div className="breadcrumbs">
            <Container>
                <div className="d-flex justify-content-between align-items-center">
                <ol>
                    <li><Link to={webUrl}>Home</Link></li>
                    <li>Contact Us</li>
                </ol>
                </div>
            </Container>
        </div>
        <ContactDetails />
        <ContactMap />
    </div>
  )
}
