import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from 'swiper/modules';
// Importing lightgallery correctly
import 'swiper/css';
import 'swiper/css/navigation';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import lgZoom from 'lightgallery/plugins/zoom';
import LightGallery from 'lightgallery/react';
import Skeleton from '@mui/material/Skeleton';

export default function ActivitySlider({ proData }) {
  const [images, setImages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (proData.arr_pro_img_arr) {
      setImages(proData.arr_pro_img_arr);
    }
  }, [proData]);

  useEffect(() => {
    const lightGalleryOptions = {
      plugins: [lgZoom],
      counter: true,
      download: true,
      getCaptionFromTitleOrAlt: false,
      showAfterLoad: false,
    };

    // Initialize lightGallery on all image links
    const imageLinks = document.querySelectorAll('.activity-slider a');

    if (window.lightGallery) {
      window.lightGallery(imageLinks, lightGalleryOptions);
    }

    setIsLoading(false); // Once images are loaded, set loading to false
  }, [images]);

  return (
    <>
      {isLoading ? (
        <Skeleton variant="rectangular" width="100%" height={400} />
      ) : (
        <Swiper
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          spaceBetween={10}
          freeMode={true}
          pagination={{
            clickable: true,
          }}
          breakpoints={{
            500: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 10,
            },
          }}
          navigation={true}
          modules={[Autoplay, Navigation]}
          className="activity-slider"
        >
          {images.map((image, index) => (
            <SwiperSlide key={index}>
              <LightGallery>
                <a
                  className="swiper-slide"
                  href={image.pro_imgs}
                  data-lg-autoplay="true"
                  data-lg-next=".lg-next"
                  data-lg-prev=".lg-prev"
                >
                  <img
                    src={image.pro_imgs}
                    alt={proData.product_name}
                    width="100%"
                    height="auto"
                  />
                </a>
              </LightGallery>
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </>
  );
}
