import React, { useState, useEffect } from "react";
import { Col, Container, Row, Button } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { Link, useParams } from "react-router-dom";
import BASE_URLS from "../../baseUrl";
import summary from "../../Images/summary.svg";
import { useCurr } from "../../Contexts/currContext";
import { useNavigate } from "react-router-dom";
import DownloadTick from "./DownloadTicks";


export const OrderDetails = () => {
  const { orderId } = useParams();
  const mainApiUrl = BASE_URLS.main;
  const webUrl = BASE_URLS.webUrl;
  const [orderDetails, setOrderDetails] = useState({});
  const { selectedCurrency, currConvert } = useCurr();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        
        const res = await fetch(`${mainApiUrl}/get_order_details.php?orderId=${orderId}`);
        const data = await res.json();

        if (data.result === 'Success') {
          setOrderDetails(data.order_list); 
        }else {
            navigate(`${webUrl}` + "account/bookings");
        }

      }
      catch (error) {
        console.error('Error fetching order details:', error);
      }
    };
    
    fetchOrderDetails();
  }, [orderId]);

  return (
    <>
      <Helmet>
        <title>Order Summary | Rathin Tourism LLC</title>
      </Helmet>
      <div className="breadcrumbs">
        <Container>
          <div className="d-flex justify-content-between align-items-center">
            <ol>
              <li>
                <Link to={webUrl}>Home</Link>
              </li>
              <li>Order Details</li>
              <li>{orderId}</li>
            </ol>
          </div>
        </Container>
      </div>
      <section className="payments">
        <Container>
          <Row>
            <Col md={{ span: 8, offset: 2 }}>
              <div className="booking success">
                <div className="booking-header">
                  <div className="bheader">
                    <img
                      src={summary}
                      width="250px"
                      height="auto" 
                      className="img-fluid pt-3 pb-3"
                      alt="Order Summary"
                      loading="lazy"
                    />
                  </div>
                </div>
                <h3 className="font-weight-bold  mt-3">Order Summary</h3>
              </div>
              <div className="mt-4">
                <h6 className="font-weight-bold">
                  Here's the summary of your booking
                </h6>
                <div className="table-responsive">
                  <table className="table table-striped">
                    <tbody>
                      <tr>
                        <th>Order No</th>
                        <td>{orderDetails.order_no}</td>
                      </tr>
                      <tr>
                        <th>Booked Date</th>
                        <td>{orderDetails.date}</td>
                      </tr>
                      <tr>
                        <th>Payment Status</th>
                        <td>{orderDetails.payment_status}</td>
                      </tr>
                      <tr>
                        <th>Product name</th>
                        <td>{orderDetails.prod_name}</td>
                      </tr>
                      <tr>
                        <th>Ticket type</th>
                        <td>{orderDetails.tick_type}</td>
                      </tr>
                      <tr>
                        <th>PAX</th>
                        <td>{orderDetails.pax}</td>
                      </tr>
                      <tr>
                        <th>Amount</th>
                        <td>{selectedCurrency} {currConvert(orderDetails.amount)}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <center className="mt-5">
                  <DownloadTick  fileUrls={orderDetails.fileUrls} />
                </center>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
