import { useState, useEffect } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

import BASE_URLS from "../../baseUrl";
import { useWishlist } from "../../Contexts/WishlistContext";
import { WishlistButton } from "../WishList/WishlistButton";
import { useCurr } from "../../Contexts/currContext";

import { Skeleton } from "@mui/material"; // Import Skeleton from MUI

function TourListBox({ destId }) {
  const mainApiUrl = BASE_URLS.main;
  const webUrl = BASE_URLS.webUrl;
  const { wishlistItems, tourListWishlist, setTourListWishlist } =
    useWishlist();
  const { selectedCurrency, currConvert } = useCurr();

  const [prodData, setProducts] = useState([]);
  const [totProd, setTotProd] = useState([]);
  const [destData2, setCityData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getCities = async () => {
      const res = await fetch(
        `${mainApiUrl}/get_cities_data.php?city_id=` + destId
      );
      const getdata = await res.json();
      setCityData(getdata);
    };
    if (destId > 0) {
      getCities();
    }
  }, [destId, mainApiUrl]);

  useEffect(() => {
    if (destId > 0) {
      const getProducts = async () => {
        const res = await fetch(
          `${mainApiUrl}/get_products.php?city_id=` + destId
        );
        const getdata = await res.json();
        setProducts(getdata.prod_list.slice(0, 8));
        setTotProd(getdata.total);
        setIsLoading(false);
      };
      getProducts();
    }else{
      setIsLoading(false);
    }
  }, [destId, mainApiUrl]);

  useEffect(() => {
    if (wishlistItems && totProd?.length > 0) {
      const initialWishlistStatus = {};
      totProd?.forEach((product) => {
        initialWishlistStatus[product.product_id] = wishlistItems.some(
          (item) => item.product_id === product.product_id
        );
      });
      setTourListWishlist(initialWishlistStatus);
    }
  }, [wishlistItems, totProd]);

  // Hide the entire section if prodData length is zero
  if (prodData && prodData.length === 0 && !isLoading) {
    return null;
  }

  return (
    <section className="list section-bg">
      <Container>
          {isLoading ? (
            <>
            <div className="section-header">
            <center><p>
                <Skeleton animation="wave" variant="text" width="40%" height={50} />
              </p></center>
            </div>
            <Row className="gy-4">
              {Array.from({ length: 8 }).map((_, index) => (
                <Col lg={3} md={4} sm={6} key={index}>
                  <Skeleton
                    animation="wave"
                    variant="rounded"
                    width="100%"
                    height={180}
                    className="mb-1"
                  />
                  <Skeleton
                    animation="wave"
                    variant="text"
                    width="60%"
                    height={20}
                    className="mb-1"
                  />
                  <Skeleton animation="wave" variant="text" width="60%" height={20} />
                  <div
                    className="d-flex flex-row justify-content-between"
                  >
                    <Skeleton animation="wave" variant="text" width="20%" height={50} />
                    <Skeleton animation="wave" variant="text" width="20%" height={50} />
                  </div>
                </Col>
              ))}
              <Col lg={12} md={12} xs={12} className="d-flex justify-content-center mt-5">
                <Skeleton animation="wave" variant="text" width="10%" height={80} />
              </Col>
              </Row>
            </>
          ) : totProd > 0 ? (
            <>
            <div className="section-header">
              <p>
                All Activities In <span>{destData2.city_name} </span>
              </p>
            </div>
            <Row className="gy-4">
              {prodData.map((item, index) => (
                <Col lg={3} md={4} sm={6} xs={6} key={index}>
                  <div className="wsk-cp-product">
                    <Link
                      to={`${webUrl}activity/${item.product_slug}`}
                      state={item.prodIdArr}
                    >
                      <div className="wsk-cp-img">
                        <img
                          loading="lazy"
                          src={item.product_image}
                          alt={item.product_name}
                          className="img-fluid"
                          width="100%" 
                          height="auto"
                        />
                        <div className="add-cart">
                          <WishlistButton
                            heartToggle={tourListWishlist[item.product_id]}
                            product={item.product_id}
                          />
                        </div>
                      </div>
                      <div className="wsk-cp-text">
                        <div className="title-product">
                          <h3>{item.product_name}</h3>
                        </div>
                        <div className="description-prod">
                        {(item.availablity) == "Not Available" ? (
                          <p className="text-danger">{item.availablity}</p>
                        ) : <p className="text-success">{item.availablity}</p>}
                        </div>
                        <div className="card-footer">
                          <div className="wcf-left">
                            from
                            <span className="price">
                              <span className="currency">{selectedCurrency}</span>{" "}
                              {currConvert(item.product_price_starts)}
                            </span>
                          </div>
                          <div className="wcf-right">
                            <p>
                              <i className="fa fa-star"></i> {item.product_rating}
                            </p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </Col>
              ))}
              <Col lg={12} md={12} xs={12} className="d-flex justify-content-center mt-5">
                <Link to={`${webUrl}activity-list`}>
                  <Button variant="primary">See All ({totProd})</Button>
                </Link>
              </Col>
              </Row>
            </>
          ) : ( null ) }
        
      </Container>
    </section>
  );
}

export default TourListBox;
