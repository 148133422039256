import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import faq from '../../Images/faq.svg';
import { Link } from 'react-router-dom';
import BASE_URLS from '../../baseUrl';

export const AskFaq = () => {
    const webUrl = BASE_URLS.webUrl;
    return (
    <section className='ask-faq'>
        <Container>
            <Row>
                <Col>
                <div className="coupon-card">
                    <div className="coupon-design">
                        <div>
                            <img src={faq} className="img-fluid" alt="" loading="lazy" width="100%" 
                            height="auto"/>
                        </div>
                        <div>
                            <h3>Can't find what are you looking for?</h3>
                        </div>
                        <Link to={`${webUrl}contact-us`}>
                            <div className='discount-code' >
                                <div id="cpnBtn" className="copy">Contact Us</div>
                            </div>
                        </Link>
                    </div>
                </div>
                </Col>
            </Row>
        </Container>
    </section>
  )
}
