import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';

export const SocialModal = ({ showSocial, onHide }) => {
    const [isCopied, setIsCopied] = useState(false);
    // Function to share on Facebook
    const shareOnFacebook = () => {
        const url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.href)}`;
        window.open(url, '_blank', 'width=600,height=400');
    };

    // Function to share on Twitter
    const shareOnTwitter = () => {
        const url = `https://twitter.com/intent/tweet?url=${encodeURIComponent(window.location.href)}`;
        window.open(url, '_blank', 'width=600,height=400');
    };

    // Function to share on WhatsApp
    const shareOnWhatsApp = () => {
        const url = `https://api.whatsapp.com/send?text=${encodeURIComponent(window.location.href)}`;
        window.open(url, '_blank', 'width=600,height=400');
    };

    // Function to share on Telegram
    const shareOnTelegram = () => {
        const url = `https://t.me/share/url?url=${encodeURIComponent(window.location.href)}`;
        window.open(url, '_blank', 'width=600,height=400');
    };
    const handleCopyURL = () => {
        // Create an input element to temporarily hold the URL
        const tempInput = document.createElement('input');
        tempInput.value = window.location.href;
        
        // Append the input element to the DOM (must be in the DOM for document.execCommand to work)
        document.body.appendChild(tempInput);
        
        // Select the URL text inside the input
        tempInput.select();
        tempInput.setSelectionRange(0, 99999); // For mobile devices
        
        // Copy the URL to the clipboard
        document.execCommand('copy');
        
        // Remove the temporary input element
        document.body.removeChild(tempInput);
        
        // Set isCopied to true and change the button text
        setIsCopied(true);
        
        // Reset isCopied after a brief delay (e.g., 2 seconds)
        setTimeout(() => {
            setIsCopied(false);
        }, 2000);
    };

    return (
    <Modal  show={showSocial} onHide={onHide} size="lmd" centered>
        <Modal.Header closeButton>
          <Modal.Title>Share</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="popup">
            <div className="content">
              <p>Share this link via</p>
              <ul className="icons">
                <Link onClick={shareOnFacebook}>
                  <i className="fab fa-facebook-f"></i>
                </Link>
                <Link onClick={shareOnTwitter}>
                  <i className="fab fa-twitter"></i>
                </Link>
                <Link onClick={shareOnWhatsApp}>
                  <i className="fab fa-whatsapp"></i>
                </Link>
                <Link onClick={shareOnTelegram}>
                  <i className="fab fa-telegram-plane"></i>
                </Link>
              </ul>
              <p>Or copy link</p>
              <div className="field">
                <i className="bi bi-link"></i>
                <input type="text" readOnly value={window.location.href} />
                <button onClick={handleCopyURL} disabled={isCopied}>
                  {isCopied ? 'Copied' : 'Copy'}
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
};
