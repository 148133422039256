import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import RangeSlider from "react-bootstrap-range-slider";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useFilter } from "../../Contexts/filterContext";
import { useCurr } from "../../Contexts/currContext";

export const MobileHeader = ({ allData, actData }) => {
  const [catData, setCatData] = useState([]);
  const [prodTags, setProdTags] = useState([]);
  useEffect(() => {
    if (allData.cat_arr) {
      setCatData(allData.cat_arr);
    }
    if (allData.arr_prod_tag) {
      setProdTags(allData.arr_prod_tag);
    }
  }, [allData]);

  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);

  const handleInputClick2 = () => {
    setIsModalOpen2(true);
  };

  const handleCloseModal1 = () => {
    setIsModalOpen1(false);
  };

  const handleCloseModal2 = () => {
    setIsModalOpen2(false);
  };

  //const [startDate, setStartDate] = useState(null);
  // Set the minDate to the current date to disable previous dates
  const minDate = new Date();
  minDate.setDate(minDate.getDate() + 1);

  const { price_value, handleFilters, locationName, act_value_list } =
    useFilter();
  const { selectedCurrency, currConvert } = useCurr();

  const [selectedDate, setSelectedDate] = useState(null);
  const [formattedDate, setFormattedDate] = useState("");
  const handleApplyDate = () => {
    setIsModalOpen2(false);
    const formattedDate = selectedDate
      ? selectedDate.toLocaleDateString("en-GB")
      : "";
    const formattedDate_escl = selectedDate
      ? selectedDate.toLocaleDateString("es-CL")
      : "";
    setFormattedDate(formattedDate);
    handleFilters("", "", "", "", "", "", null, null, formattedDate_escl);
  };

  const [chips, setChips] = useState([
    {
      name: "Activity type",
      icon: "bi-tag",
    },
    {
      name: "Category",
      icon: "bi-clipboard-check",
    },
    {
      name: "Price",
      icon: "bi-currency-dollar",
    },
    {
      name: "Others",
      icon: "bi-lightning",
    },
  ]);

  // State and functions for modals
  const [showActivityModal, setShowActivityModal] = useState(false);
  const [showCategoryModal, setShowCategoryModal] = useState(false);
  const [showPriceModal, setShowPriceModal] = useState(false);
  const [showOthersModal, setShowOthersModal] = useState(false);

  const handleClose = () => {
    setShowActivityModal(false);
    setShowCategoryModal(false);
    setShowPriceModal(false);
    setShowOthersModal(false);
  };

  const [value, setValue] = useState(0);
  const handleChange = (changeEvent) => {
    setValue(changeEvent.target.value);
  };

  const handleRemoveChip = (chip) => {
    setChips((prevChips) => prevChips.filter((c) => c !== chip));
  };
  return (
    <section className="list fixed fixed-top mobile-block" id="mobile-filter">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="">
              <div className="position-relative d-flex align-items-center">
                <span className="position-absolute mt--12 mx-4">
                  <i className="bi bi-search"></i>
                </span>
                <input
                  type="search"
                  className="ps-12 form-control"
                  placeholder="search for activities"
                  onKeyUp={(event) =>
                    handleFilters(
                      "",
                      "",
                      "",
                      "",
                      "",
                      "",
                      event.target.value,
                      null,
                      null
                    )
                  }
                />
              </div>

              <div className="position-relative d-flex align-items-center">
                <span className="position-absolute mt--12 mx-4">
                  <i className="bi bi-geo-alt"></i>
                </span>
                <input
                  type="search"
                  className="ps-12 form-control"
                  placeholder="search location"
                  value={locationName || ""}
                  onChange={(event) =>
                    handleFilters(
                      "",
                      "",
                      "",
                      "",
                      "",
                      "",
                      null,
                      event.target.value,
                      null
                    )
                  }
                />
              </div>

              <div className="position-relative d-flex align-items-center">
                <span className="position-absolute mt--12 mx-4">
                  <i className="bi bi-calendar"></i>
                </span>
                <input
                  type="text"
                  className="form-control ps-12"
                  defaultValue={formattedDate}
                  readOnly
                  onClick={handleInputClick2}
                  placeholder="add dates"
                  name=""
                  id="datepicker2"
                  onKeyUp={(event) =>
                    handleFilters(
                      "",
                      "",
                      "",
                      "",
                      "",
                      "",
                      null,
                      null,
                      event.target.value
                    )
                  }
                />
              </div>
            </div>
            <div className="chip">
              {chips.map((chip, index) => (
                <span key={index} className="badge bg-secondary m-1">
                  <span
                    onClick={() => {
                      switch (chip.name) {
                        case "Activity type":
                          setShowActivityModal(true);
                          break;
                        case "Category":
                          setShowCategoryModal(true);
                          break;
                        case "Price":
                          setShowPriceModal(true);
                          break;
                        case "Others":
                          setShowOthersModal(true);
                          break;
                        default:
                          break;
                      }
                    }}
                  >
                    <i className={`bi ${chip.icon}`}></i> {chip.name}
                  </span>
                  <button
                    type="button"
                    className="btn-close"
                    onClick={() => handleRemoveChip(chip)}
                  ></button>
                </span>
              ))}
            </div>
          </div>
        </div>
      </div>

      <Modal show={showActivityModal} onHide={handleClose} scrollable centered>
        <Modal.Header closeButton>
          <Modal.Title>Acitivity Types</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>Select one or more</h5>
          <div className="search-list-icons">
            {actData.map((item, index) => (
              <div className="search-icons" key={index}>
                <input
                  type="checkbox"
                  className="btn-check"
                  id={"fq3" + item.act_id}
                  autoComplete="off"
                  onClick={(event) =>
                    handleFilters(
                      "",
                      "",
                      "",
                      "",
                      "",
                      item.act_id,
                      null,
                      null,
                      null
                    )
                  }
                  checked={act_value_list.includes(item.act_id)}
                />
                <label
                  className="btn btn-primary"
                  htmlFor={"fq3" + item.act_id}
                >
                  <i className={item.act_icon}></i>
                  {item.act_name}
                </label>
              </div>
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Apply
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showCategoryModal} onHide={handleClose} scrollable centered>
        <Modal.Header closeButton>
          <Modal.Title>Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {catData.map((item, index) => (
            <div className="form-check" key={index}>
              <input
                className="form-check-input"
                type="checkbox"
                name=""
                id={"fc3" + item.cat_id}
                onClick={() =>
                  handleFilters(
                    item.cat_id,
                    "",
                    "",
                    "",
                    "",
                    "",
                    null,
                    null,
                    null
                  )
                }
                value=""
              />
              <label className="form-check-label" htmlFor={"fc3" + item.cat_id}>
                {item.cat_name}
              </label>
            </div>
          ))}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Apply
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showPriceModal} onHide={handleClose} scrollable centered>
        <Modal.Header closeButton>
          <Modal.Title>Price Range</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <RangeSlider
            value={price_value}
            onChange={(event) =>
              handleFilters(
                "",
                event.target.value,
                "",
                "",
                "",
                "",
                null,
                null,
                null
              )
            }
            className="custom-slider"
            min={0}
            max={5000}
          />
          <div className="d-flex justify-content-between pt-2">
            <span>{selectedCurrency} 0</span>
            <span>
              {selectedCurrency} {currConvert(price_value)}
            </span>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Apply
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showOthersModal} onHide={handleClose} scrollable centered>
        <Modal.Header closeButton>
          <Modal.Title>Others</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {prodTags.map((item, index) => (
            <div className="form-check" key={index}>
              <input
                className="form-check-input"
                type="checkbox"
                name=""
                id={"fp3" + item.pro_tag_id}
                value=""
                onClick={(event) =>
                  handleFilters(
                    "",
                    "",
                    "",
                    "",
                    item.pro_tag_id,
                    "",
                    null,
                    null,
                    null
                  )
                }
              />
              <label
                className="form-check-label"
                htmlFor={"fp3" + item.pro_tag_id}
              >
                {item.pro_tag_name}
              </label>
            </div>
          ))}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Apply
          </Button>
        </Modal.Footer>
      </Modal>
      {isModalOpen2 && (
        <Modal
          show={isModalOpen2}
          onHide={handleCloseModal2}
          className="calendar"
          size="lg"
          centered
          scrollable
        >
          <Modal.Header closeButton>
            <Modal.Title>Select Dates</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <DatePicker
              renderCustomHeader={({
                monthDate,
                customHeaderCount,
                decreaseMonth,
                increaseMonth,
              }) => (
                <div>
                  <button
                    aria-label="Previous Month"
                    className={
                      `react-datepicker__navigation react-datepicker__navigation--previous ${
                        customHeaderCount === 1 ? 'invisible' : ''
                      }`
                    }
                    onClick={decreaseMonth}
                  >
                    <span
                      className={
                        "react-datepicker__navigation-icon react-datepicker__navigation-icon--previous"
                      }
                    >
                      {"<"}
                    </span>
                  </button>
                  <span className="react-datepicker__current-month">
                    {monthDate.toLocaleString("en-US", {
                      month: "long",
                      year: "numeric",
                    })}
                  </span>
                  <button
                    aria-label="Next Month"
                    className={
                      `react-datepicker__navigation react-datepicker__navigation--next ${
                        customHeaderCount === 0 ? 'invisible' : ''
                      }`
                    }
                    onClick={increaseMonth}
                  >
                    <span
                      className={
                        "react-datepicker__navigation-icon react-datepicker__navigation-icon--next"
                      }
                    >
                      {">"}
                    </span>
                  </button>
                </div>
              )}
              selected={selectedDate}
              onChange={(date) => setSelectedDate(date)}
              monthsShown={2}
              inline
              minDate={minDate}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleApplyDate}>
              Apply
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </section>
  );
};
