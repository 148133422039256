import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import { useCurr } from "../../Contexts/currContext";
import { ActivityListDetailModal } from "./ActivityListDetailModal";
import { Skeleton } from "@mui/material";

function formatDate(date) {
  const options = { month: "short", day: "numeric" };
  return new Date(date).toLocaleDateString("en-US", options);
}

export const ActivityList = ({ proData, actList, openCalendarModal, handleSelect, selectedDate,
}) => {
  const { selectedCurrency, currConvert } = useCurr();
  const [show, setShow] = useState(false);
  const [selectedActId, setSelectedActId] = useState(null);
  const handleClose = () => setShow(false);
  const handleShow = (actId) => {
    setSelectedActId(actId);
    setShow(true);
  };

  const CHARACTER_LIMIT = 150;

  const renderSkeletons = () => {
    return (
      <>
        {[...Array(2)].map((_, index) => (
          <div className="card" key={index}>
            <div className="header-width">
              <div className="card-header">
                <Skeleton
                  sx={{ bgcolor: "transparent" }}
                  width="50%"
                  height={20}
                  animation="wave"
                />
              </div>
              <div className="card-body">
                <Skeleton
                  variant="text"
                  width="100%"
                  height={100}
                  animation="wave"
                />
              </div>
            </div>
            <div className="card-footer gap-0">
              <Skeleton
                variant="text"
                width="50%"
                height={30}
                animation="wave"
              />
              <Skeleton
                variant="text"
                width="100%"
                height={60}
                animation="wave"
              />
            </div>
          </div>
        ))}
      </>
    );
  };
  const sortedActList = [...actList];
  sortedActList.sort((a, b) => {
    // For ascending order
    return a.actAmount - b.actAmount;

    // For descending order
    // return b.actAmount - a.actAmount;
  });


  return (
    <>
      <div className="col-md-12" id="next-dates-text">
        <p className="selected-date font-weight-bold mb-3">
          {selectedDate && proData.tick_type !== 3
            ? `Available on ${formatDate(selectedDate)}`
            : "Available options"}
        </p>
      </div>
      <div className="activity-lists" id="activity-lists">
        {sortedActList.length > 0
          ? sortedActList.map((item) => (
            <div className="card" key={item.actId}>
              <div className="header-width">
                <div className="card-header">
                  <span className={item.color}>
                    <i className={item.icon}></i> {item.text}
                  </span>
                  <h6 className="fs-6">{item.actTitle}</h6>
                </div>
                <div className="card-body">
                  <p className="card-text">
                    <span
                      dangerouslySetInnerHTML={{
                        __html:
                          item.actDesc.length > CHARACTER_LIMIT
                            ? `${item.actDesc.slice(0, CHARACTER_LIMIT)}...`
                            : item.actDesc,
                      }}
                    ></span>
                  </p>
                  {item.actDesc.length > CHARACTER_LIMIT && (
                    <Button
                      variant="light"
                      onClick={() => handleShow(item.actId)}
                    >
                      More details <i className="bi bi-chevron-down"></i>
                    </Button>
                  )}
                </div>
              </div>
              {item.actAmount > 0 ? (
                <div className="card-footer">
                  <div className="card-footer-left">
                    <span>
                      <small>From</small>
                    </span>
                    <span className="wcf-left">
                      {item.actAmount < item.tick_original_price ? (
                        <div className="containers pb-2">
                          <s>
                            <span className="currency">{selectedCurrency}</span>{" "}
                            {currConvert(item.tick_original_price)}
                          </s>

                        </div>
                      ) : null}
                    </span>

                    <p className="fw-bold fs-6 d-flex align-items-center justify-content-between">
                      {selectedCurrency} {currConvert(item.actAmount)}  {item.actAmount < item.tick_original_price ? (<span className="hot_badge">{item.tick_discount}</span>) : null}
                    </p>
                  </div>
                  {selectedDate || proData.tick_type === 3 ? (
                    <div className="card-footer-right">
                      <Button
                        variant="primary"
                        onClick={() => handleSelect(item)}
                      >
                        Select
                      </Button>
                    </div>
                  ) : (
                    <div className="card-footer-right">
                      <span>
                        <small>
                          <i className="bi bi-calendar-check"></i> Next
                          availability: {formatDate(item.actAvailDate)}
                        </small>
                      </span>
                      <Button
                        variant="primary"
                        onClick={() => openCalendarModal(item)}
                      >
                        Check availability
                      </Button>
                    </div>
                  )}
                </div>
              ) :
                <div className="card-footer">
                  <div className="card-footer-left">
                    <span>
                      <small></small>
                    </span>
                    <span className="wcf-left">

                    </span>

                    <p className="fw-bold fs-6 d-flex align-items-center justify-content-between">
                     
                    </p>
                  </div>
                  <div className="card-footer-right">
                    <span>
                      <small className="text-danger"> Not Available</small>
                    </span>
                  </div>
                </div>}
            </div>
          ))
          : renderSkeletons()}
      </div>
      <ActivityListDetailModal
        show={show}
        onHide={handleClose}
        actItem={actList.find((item) => item.actId === selectedActId)}
        proData={proData}
        openCalendarModal={openCalendarModal}
        selectedDate={selectedDate}
        item={sortedActList}
        handleSelect={handleSelect}
        formatDate={formatDate}
      />
    </>
  );
};
