import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useAuth } from '../../Contexts/AuthContext';
import BASE_URLS from '../../baseUrl';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Settings() {
  const { authUser, isLoggedInWithGmail } = useAuth();
  const mainApiUrl = BASE_URLS.main;
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [validationMessage, setValidationMessage] = useState('');
  const [showPasswordSetup, setShowPasswordSetup] = useState(isLoggedInWithGmail);
  const [currentVisible, setCurrentVisible] = useState(false);
  const [newVisible, setNewVisible] = useState(false);
  const [confirmVisible, setConfirmVisible] = useState(false);

  const toggleCurrentVisibility = () => {
    setCurrentVisible(!currentVisible);
  };

  const toggleNewVisibility = () => {
    setNewVisible(!newVisible);
  };

  const toggleConfirmVisibility = () => {
    setConfirmVisible(!confirmVisible);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setValidationMessage(''); // Reset validation message

    if (showPasswordSetup) {
      // Password setup logic
      if (newPassword.length < 8) {
        setValidationMessage('New password must be at least 8 characters long.');
        return;
      }
      // Password change logic here
      toast.success('Password set up successfully.');
    } else {
      // Change password logic
      if (currentPassword !== authUser.pw_hint) {
        setValidationMessage('Incorrect current password.');
        return;
      } else if (newPassword !== confirmNewPassword) {
        setValidationMessage('New password and confirm new password must match.');
        return;
      } else if (newPassword.length < 8) {
        setValidationMessage('New password must be at least 8 characters long.');
        return;
      }
      
      // Password change logic here

      // Send the updated data to the server
      const response = await fetch(`${mainApiUrl}update_user_password.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_id: parseInt(localStorage.getItem('setAuthUser')),
          user_pass: newPassword,
        }),
      });
      
      if (response.ok) {
        // The data was successfully updated on the server
        toast.success('Your password was successfully updated!', {
          position: toast.POSITION.TOP_CENTER,
        });

        // Clear the input values
        setCurrentPassword('');
        setNewPassword('');
        setConfirmNewPassword('');
      } else {
        toast.error('Please check your information. Your profile update failed!', {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
  };

  useEffect(() => {
    setShowPasswordSetup(isLoggedInWithGmail); // Update showPasswordSetup based on the user's login method
  }, [isLoggedInWithGmail]);

  return (
    <>
      {showPasswordSetup ? ( 
        <>
          <h4 className='font-weight-bold'>Password Set Up</h4>
          <p className='small'>You logged in with your Gmail account</p>
        </>
      ) : (
        <>
          <h4 className='font-weight-bold'>Change Password</h4>
          <p className='small'>Change your password regularly to improve account security.</p>
          <Form className="mt-5" onSubmit={handleFormSubmit}>
              <Row className="mb-4 gy-4">
                <Col lg={6} md={6} sm={12}>
                  <div className="form-group">
                    <label>Current password *</label>
                    <div className="mt-2 position-relative d-flex align-items-center">
                        <input
                          type={currentVisible ? 'text' : 'password'}
                          className="form-control"
                          name="currentPassword"
                          minLength={8}
                          maxLength={15}
                          value={currentPassword}
                          onChange={(e) => setCurrentPassword(e.target.value)}
                          required
                          autoComplete=''
                        />
                        <span
                        className="svg-icon svg-icon-2 position-absolute mx-4 right-0"
                        onClick={toggleCurrentVisibility}
                      >
                        <i
                          className={`text-blue bi bi-${
                            currentVisible ? 'eye-slash-fill' : 'eye-fill'
                          }`}
                        ></i>
                      </span>
                    </div>
                  </div>
                </Col>
              </Row>

              <Row className="mb-4 gy-4">
                <Col lg={6} md={6} sm={12}>
                  <div className="form-group">
                    <label>New password *</label>
                    <div className="mt-2 position-relative d-flex align-items-center">
                        <input
                          type={newVisible ? 'text' : 'password'}
                          className="form-control"
                          name="newPassword"
                          minLength={8}
                          maxLength={15}
                          value={newPassword}
                          onChange={(e) => setNewPassword(e.target.value)}
                          required
                          autoComplete=''
                        />
                        <span
                        className="svg-icon svg-icon-2 position-absolute mx-4 right-0"
                        onClick={toggleNewVisibility}
                      >
                        <i
                          className={`text-blue bi bi-${
                            newVisible ? 'eye-slash-fill' : 'eye-fill'
                          }`}
                        ></i>
                      </span>
                    </div>
                  </div>
                </Col>
              </Row>

              <Row className="mb-4 gy-4">
                <Col lg={6} md={6} sm={12}>
                  <div className="form-group">
                    <label>Confirm password *</label>
                    <div className="mt-2 position-relative d-flex align-items-center">
                        <input
                          type={confirmVisible ? 'text' : 'password'}
                          className="form-control"
                          name="confirmNewPassword"
                          minLength={8}
                          maxLength={15}
                          value={confirmNewPassword}
                          onChange={(e) => setConfirmNewPassword(e.target.value)}
                          required
                          autoComplete=''
                        />
                        <span
                        className="svg-icon svg-icon-2 position-absolute mx-4 right-0"
                        onClick={toggleConfirmVisibility}
                      >
                        <i
                          className={`text-blue bi bi-${
                            confirmVisible ? 'eye-slash-fill' : 'eye-fill'
                          }`}
                        ></i>
                      </span>
                    </div>
                  </div>
                </Col>
              </Row>

            {validationMessage && <p className="text-danger">{validationMessage}</p>}

            <Button variant="primary" type="submit">
              {showPasswordSetup ? 'Set Password' : 'Save'}
            </Button>
          </Form>
        </>
      )}
    </>
  );
}

export default Settings;
