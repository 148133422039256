import React, { useState, useEffect } from 'react';
import BASE_URLS from '../../baseUrl';
import { Skeleton } from '@mui/material';

export const ContactInfo = () => {
    const webUrl = BASE_URLS.main;
    const [expectContactDetails, setContactDetails] = useState(null); // Initialize as null
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchContactDetails = async () => {
            try {
                const res = await fetch(`${webUrl}/get_footer_useful_links.php?mode=contact_us`);
                const data = await res.json();
                setContactDetails(data.data);
            } catch (error) {
                console.error('Error fetching contact details:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchContactDetails();
    }, [webUrl]);

    return (
        <div className="info-container d-flex flex-column align-items-center justify-content-center">
            {loading ? (
                // Display Skeleton while loading
                Array.from({ length: 4 }).map((_, index) => (
                    <Skeleton key={index} className="mb-2" animation="wave" variant="rectangular" width="100%" height={110}/>
                ))
            ) : (
                // Display contact details when data is loaded
                <>
                    <div className="info-item d-flex">
                        <i className="bi bi-geo-alt flex-shrink-0"></i>
                        <div>
                            <h4>Location:</h4>
                            <p>{expectContactDetails && expectContactDetails.location}</p>
                        </div>
                    </div>

                    <div className="info-item d-flex">
                        <i className="bi bi-envelope flex-shrink-0"></i>
                        <div>
                            <h4>Email:</h4>
                            <p>{expectContactDetails && expectContactDetails.con_email}</p>
                        </div>
                    </div>

                    <div className="info-item d-flex">
                        <i className="bi bi-phone flex-shrink-0"></i>
                        <div>
                            <h4>Call:</h4>
                            <p>{expectContactDetails && expectContactDetails.cell_no}</p>
                        </div>
                    </div>

                    <div className="info-item d-flex">
                        <i className="bi bi-clock flex-shrink-0"></i>
                        <div>
                            <h4>Open Hours:</h4>
                            <p>{expectContactDetails && expectContactDetails.open_hrs}</p>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};
