import React, { useState, useEffect } from 'react';
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Skeleton } from "@mui/material";
import "bootstrap-icons/font/bootstrap-icons.css";
import BASE_URLS from '../baseUrl';

function PrivacyPolicy() {
  const webUrl = BASE_URLS.main;
  const [privacyPolicyData, setPrivacyPolicyData] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPrivacyPolicy = async () => {
      try {
        const res = await fetch(`${webUrl}/get_footer_useful_links.php?mode=footer_useful`);
        const data = await res.json();
        if (data && data.data && data.data.privacy_policy) {
          setPrivacyPolicyData(data.data.privacy_policy);
        }
      } catch (error) {
        console.error('Error fetching privacy policy data:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchPrivacyPolicy();
  }, [webUrl]);

  return (
    <div className='body-content'>
      <Helmet>
        <title>Privacy Policy | Rathin Tourism LLC</title>
      </Helmet>
      <div className="breadcrumbs">
        <Container>
          <div className="d-flex justify-content-between align-items-center">
            <ol>
              <li><Link to={webUrl}>Home</Link></li>
              <li>Privacy Policy</li>
            </ol>
          </div>
        </Container>
      </div>
      <section className="terms-conditions clr">
        <Container>
          <Row>
            <Col md={{ span: 8, offset: 2 }}>
              {loading ? (
                <div className="section-header">
                  <Skeleton animation="wave" variant="text" width="40%" height={40} className="mb-3" />
                  {Array.from({ length: 25 }).map((_, index) => (
                    <Skeleton key={index} animation="wave" variant="text" width="100%" height={20} />
                  ))}
                </div>
              ) : (
                <>
                  <div className="section-header">
                    <p className="text-left">Privacy <span>Policy</span></p>
                  </div>
                  <span dangerouslySetInnerHTML={{ __html: privacyPolicyData }}></span>
                </>
              )}
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
}

export default PrivacyPolicy;
