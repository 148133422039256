import React from 'react'
import {Helmet} from "react-helmet";
import { Link } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { Faq } from './Faq';
import BrowseCategory from './BrowseCategory';
import { AskFaq } from './AskFaq';
import BASE_URLS from '../../baseUrl';

export const HelpCenter = () => {
  const webUrl = BASE_URLS.webUrl;
  return (
    <div className='body-content'>
        <Helmet>
            <title>Help Center | Rathin Tourism LLC</title>
        </Helmet>
        <div className="breadcrumbs">
            <Container>
                <div className="d-flex justify-content-between align-items-center">
                <ol>
                    <li><Link to={webUrl}>Home</Link></li>
                    <li>Help Center</li>
                </ol>
                </div>
            </Container>
        </div>
        <Faq />
        <BrowseCategory />
        <AskFaq />
    </div>
  )
}
