import React, { useEffect, useState } from "react";
import { useCurr } from "../../Contexts/currContext";
import Button from "react-bootstrap/Button";
import BASE_URLS from "../../baseUrl";
import { Skeleton } from "@mui/material";
import { AvailableDatesModal } from "./availableDatesModal";
import { ActivityModal } from "./ActivityModal";
import { SelectPreference } from "./selectPreference";
import { SelectSpec } from "./ActivitySpecModal";
import { ActivityListDetailModal } from "./ActivityListDetailModal";

function formatDate(date) {
    const options = { month: "short", day: "numeric" };
    return new Date(date).toLocaleDateString("en-US", options);
}

export const CheckoutAddonSidebar = (props) => {
    const bookingDetailsData = props.bookingDetails;
    const mainApiUrl = BASE_URLS.main;
    const webUrl = BASE_URLS.webUrl;
    var id = props.bookingDetails.addon_prod_slug;
    const [proData, setProd] = useState([]);
    const [proDataArr, setproDataArr] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedDate, setSelectedDate] = useState(null);

    const [specCount, setSpecCount] = useState(0);
    const [tick_type, setTick_type] = useState(0);
    const [enabledDates, setEnabledDates] = useState([]);

    const [showCalendarModal, setShowCalendarModal] = useState(false);
    const [showActivityModal, setShowActivityModal] = useState(false);
    const [showPreferenceModal, setShowPreferenceModal] = useState(false);
    const [showSpecModal, setShowSpecModal] = useState(false);
    const [selItem, setSelItem] = useState(null);

    const closeActivityModal = () => {
        setShowActivityModal(false);
    };

    const openCalendarModal = (item) => {
        setSelItem(item);
        closeActivityModal();
        setShowCalendarModal(true);
        setShowSpecModal(false);
        setShowPreferenceModal(false);
    };

    const closeCalendarModal = () => {
        setShowCalendarModal(false);
    };

    const handleOpenAvailableDatesModal = () => {
        openCalendarModal();
        hidePreferenceModal();
    };

    const closePreferenceModal = () => {
        setShowPreferenceModal(false);
    };

    const openPreferenceModal = () => {
        setShowPreferenceModal(true);
    };

    const openSpecModal = () => {
        setShowSpecModal(true);
    };
    const closeSpecModal = () => {
        setShowSpecModal(false);
    };
    const hideSpecModal = () => {
        setShowSpecModal(false);
        setShowPreferenceModal(false);
    };

    const hidePreferenceModal = () => {
        setShowPreferenceModal(false);
    };

    const handleEditDate = () => {
        hidePreferenceModal();
        openPreferenceModal();
    };
    useEffect(() => {
        // Set specCount to proData.specCount when proData changes
        if (proData && proData.specCount !== undefined) {
            setSpecCount(proData.specCount);
        }
        if (proData && proData.tick_type !== undefined) {
            setTick_type(proData.tick_type);
        }
        setEnabledDates(proData.arr_avail_dates);
    }, [proData]);

    const [selectedItem, setSelectedItem] = useState(null);
    const handleSelect = (item) => {

        if (specCount > 2 || tick_type == 1) {
            setSelectedItem(item); // Set the selected item in state
            openSpecModal(item, selectedDate); // Set the selected item in state
        } else {
            setSelectedItem(item); // Set the selected item in state
            openPreferenceModal(item, selectedDate); // Pass item and selectedDate to openPreferenceModal
        }
    };

    const handleDelete = (item) => {
        props.setBookingDetailsAddon([]);
    };

    useEffect(() => {
        const getProduct = async () => {
            try {
                console.log('get_products_data' + id);
                if (id !== '' && id !== undefined) {
                    const res = await fetch(`${mainApiUrl}/get_products_data_addon.php?product_slug=${id}`);
                    const getdata = await res.json();
                    if (getdata) {
                        setproDataArr(getdata);
                        setProd(getdata);

                    }
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
            finally {
                setIsLoading(false);
            }

        };

        getProduct();
    }, [mainApiUrl, id]);

    const [actList, setActList] = useState([]);

    useEffect(() => {
        if (proData.display_tickets_new) {
            setActList(proData.display_tickets_new.specification_value_names);
        }
    }, [proData.display_tickets_new]);

    const sortedActList = [...actList];
    sortedActList.sort((a, b) => {
        return a.actAmount - b.actAmount;
    });
    const CHARACTER_LIMIT = 150;
    const { selectedCurrency, currConvert } = useCurr();
    const [show, setShow] = useState(false);
    const [selectedActId, setSelectedActId] = useState(null);
    const handleClose = () => setShow(false);
    const handleShow = (actId) => {
        setSelectedActId(actId);
        setShow(true);
    };


    const renderSkeletons = () => {
        return (
            <>
                {[...Array(2)].map((_, index) => (
                    <div className="card" key={index}>
                        <div className="header-width-addon">
                            <div className="card-header">
                                <Skeleton
                                    sx={{ bgcolor: "transparent" }}
                                    width="50%"
                                    height={20}
                                    animation="wave"
                                />
                            </div>
                            <div className="card-body">
                                <Skeleton
                                    variant="text"
                                    width="100%"
                                    height={100}
                                    animation="wave"
                                />
                            </div>
                        </div>
                        <div className="card-footer gap-0">
                            <Skeleton
                                variant="text"
                                width="50%"
                                height={30}
                                animation="wave"
                            />
                            <Skeleton
                                variant="text"
                                width="100%"
                                height={60}
                                animation="wave"
                            />
                        </div>
                    </div>
                ))}
            </>
        );
    };

    // return (
    //     <>
    //         {id != '' ? (
    //             <div className="checkout-sidebar sticky-top mt-5">
    //                 <h5>Book Together to save</h5>
    //                 <p>{proData.product_name}</p>

    //                 <div className="checkout-table table-responsive">
    //                     <div className="activity-lists" id="activity-lists">
    //                         {sortedActList.length > 0
    //                             ? sortedActList.map((item) => (
    //                                 <div className="card" key={item.actId}>
    //                                     <div className="header-width-addon">
    //                                         <div className="card-header">
    //                                             <span className={item.color}>
    //                                                 <i className={item.icon}></i> {item.text}
    //                                             </span>
    //                                             <h6 className="fs-6">{item.actTitle}</h6>
    //                                         </div>
    //                                         <div className="card-body">
    //                                             <div className="card-footer-left">
    //                                                 <span>
    //                                                     <small>From</small>
    //                                                 </span>
    //                                                 <span className="wcf-left">
    //                                                     {item.actAmount < item.tick_original_price ? (
    //                                                         <div className="containers pb-2">
    //                                                             <s>
    //                                                                 <span className="currency">{selectedCurrency}</span>{" "}
    //                                                                 {currConvert(item.tick_original_price)}
    //                                                             </s>

    //                                                         </div>
    //                                                     ) : null}
    //                                                 </span>

    //                                                 <p className="fw-bold fs-6 d-flex align-items-center justify-content-between">
    //                                                     {selectedCurrency} {currConvert(item.actAmount)}  {item.actAmount < item.tick_original_price ? (<span className="hot_badge">{item.tick_discount}</span>) : null}
    //                                                 </p>
    //                                             </div>

    //                                             {selectedDate || proData.tick_type === 3 ? (
    //                                                 <div className="card-footer-right d-flex align-items-center justify-content-between">
    //                                                     <Button
    //                                                         variant="primary"
    //                                                         onClick={() => handleSelect(item)}
    //                                                     >
    //                                                         Select
    //                                                     </Button>
    //                                                     {props.bookingDetails_addon.totalAmount > 0 ? (
    //                                                         <Button
    //                                                             variant="danger"
    //                                                             onClick={() => handleDelete(item)}
    //                                                         >
    //                                                             Delete
    //                                                         </Button>
    //                                                     ) : null}
    //                                                 </div>
    //                                             ) : (
    //                                                 <div className="card-footer-right d-flex justify-content-between align-items-center" >
    //                                                     <span>
    //                                                         <small>
    //                                                             <i className="bi bi-calendar-check"></i> Next
    //                                                             availability: {formatDate(item.actAvailDate)}
    //                                                         </small>
    //                                                     </span>
    //                                                     <Button
    //                                                         variant="primary"
    //                                                         onClick={() => openCalendarModal(item)}
    //                                                     >
    //                                                         Check availability
    //                                                     </Button>
    //                                                 </div>
    //                                             )}

    //                                         </div>
    //                                     </div>
    //                                 </div>
    //                             ))
    //                             : renderSkeletons()}
    //                     </div>

    //                     {/* Modals */}
    //                     <ActivityModal
    //                         proData={proData} // Use proDataArr as a prop
    //                         showActivity={showActivityModal}
    //                         onHide={closeActivityModal}
    //                         openAvailableDatesModal={handleOpenAvailableDatesModal}
    //                     />

    //                     <AvailableDatesModal
    //                         proData={proData}
    //                         showCalendar={showCalendarModal}
    //                         onHide={closeCalendarModal}
    //                         onDateSelected={setSelectedDate}
    //                         selectedDate={selectedDate}
    //                         enabledDates={enabledDates}
    //                         selItem={selItem}
    //                         handleSelect={handleSelect}
    //                     />

    //                     <SelectPreference
    //                         proData={proData}
    //                         setShowPreferenceModal={setShowPreferenceModal}
    //                         item={selectedItem}
    //                         selectedDate={selectedDate}
    //                         showPreference={showPreferenceModal}
    //                         onHide={closePreferenceModal}
    //                         openCalendarModal={openCalendarModal}
    //                         handleEditDate={handleEditDate}
    //                         hidePreferenceModal={hidePreferenceModal}
    //                         setBookingDetailsAddon={props.setBookingDetailsAddon}
    //                         hideSpecModal={hideSpecModal}
    //                     />

    //                     <SelectSpec
    //                         proData={proData}
    //                         item={selectedItem}
    //                         selectedDate={selectedDate}
    //                         showSpec={showSpecModal}
    //                         onHide={closeSpecModal}
    //                         closeSpecModal={closeSpecModal}
    //                         openCalendarModal={openCalendarModal}
    //                         handleEditDate={handleEditDate}
    //                         hideSpecModal={hideSpecModal}
    //                         setSelectedItem={setSelectedItem}
    //                         openPreferenceModal={openPreferenceModal}
    //                         setSpecCount={setSpecCount}
    //                         specCount={specCount}
    //                     />
    //                     <ActivityListDetailModal
    //                         show={show}
    //                         onHide={handleClose}
    //                         actItem={actList.find((item) => item.actId === selectedActId)}
    //                         proData={proData}
    //                         openCalendarModal={openCalendarModal}
    //                         selectedDate={selectedDate}
    //                         item={sortedActList}
    //                         handleSelect={handleSelect}
    //                         formatDate={formatDate}
    //                     />
    //                 </div>
    //             </div>
    //         ) : null}
    //     </>
    // );
};
