import React, { useState, useEffect } from 'react';
import BASE_URLS from '../../baseUrl';
import { Skeleton } from '@mui/material';

export const ContactMap = () => {
  const webUrl = BASE_URLS.main;
  const [expectContactDetails, setContactDetails] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchContactDetails = async () => {
      try {
        const res = await fetch(`${webUrl}/get_footer_useful_links.php?mode=contact_us`);
        const data = await res.json();
        setContactDetails(data.data);
      } catch (error) {
        console.error('Error fetching contact details:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchContactDetails();
  }, [webUrl]);

  return (
    <>
      {loading ? (
        <Skeleton variant="rectangular" animation="wave" width="100%" height={500} />
      ) : (
        <span dangerouslySetInnerHTML={{ __html: expectContactDetails && expectContactDetails.google_url }}></span>
      )}
    </>
  );
};
