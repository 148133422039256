import React from "react";
import { useState, useEffect } from "react";
import Col from "react-bootstrap/Col";
import Skeleton from "@mui/material/Skeleton"; // Import Skeleton from MUI

import { Link } from "react-router-dom";
import BASE_URLS from "../../baseUrl";
import { useWishlist } from "../../Contexts/WishlistContext";
import { WishlistButton } from "../WishList/WishlistButton";
import { useCurr } from "../../Contexts/currContext";

function TourList({ cityData }) {
  const { wishlistItems, tourListWishlist, setTourListWishlist } = useWishlist();
  const { selectedCurrency, currConvert } = useCurr();
  const mainApiUrl = BASE_URLS.main;
  const webUrl = BASE_URLS.webUrl;

  const [prodData, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  // console.log(prodData);

  useEffect(() => {
    // console.log(cityData.product_priority);
    const getProducts = async () => {
      const res = await fetch(`${mainApiUrl}/get_products.php?limit=4&city_id=${cityData.cityId}`);
      const getdata = await res.json();

      // Extract the product IDs from the sorted priority list
      const priorityIds = cityData.product_priority.split(',').map(id => parseInt(id.trim()));

      // Sort products based on the priority order
      const sortedProdData = getdata.prod_list.sort((a, b) => {
        const idA = parseInt(a.product_id);
        const idB = parseInt(b.product_id);

        const indexA = priorityIds.indexOf(idA);
        const indexB = priorityIds.indexOf(idB);

        // Handle cases where one or both IDs are not in the priority list
        if (indexA === -1 && indexB === -1) {
          return 0; // Keep the current order if both IDs are not in the priority list
        } else if (indexA === -1) {
          return 1; // Place products with ID not in priority list after
        } else if (indexB === -1) {
          return -1; // Place products with ID not in priority list before
        } else {
          return indexA - indexB; // Compare indices in the priority list
        }
      });
      setProducts(sortedProdData.slice(0, 4));
      setLoading(false);
    };
    getProducts();

  }, [mainApiUrl, cityData.cityId]);
  useEffect(() => {
    // Initialize the wishlist status object
    if (wishlistItems && prodData?.length > 0) {
      const initialWishlistStatus = {};
      prodData?.forEach((product) => {
        initialWishlistStatus[product.product_id] = wishlistItems.some(
          (item) => item.product_id === product.product_id
        );
      });
      setTourListWishlist(initialWishlistStatus);
    }
  }, [wishlistItems, prodData]);

  return (
    <>
      {loading ? (
        // Render skeleton loading while data is being fetched
        Array.from({ length: 4 }).map((_, index) => (
          <Col lg={6} md={6} sm={12} xs={12} key={index}>
            <div className="wsk-cp-product">
              <Skeleton animation="wave" variant="rectangular" width="100%" height={200} />
            </div>
          </Col>
        ))
      ) : prodData.length === 0 ? (
        // Render message when no activity is available
        <div className="text-center">
          <p>No activity at the moment...</p>
        </div>
      ) : (
        // Render product items when data is fetched
        prodData.map((item, index) => (
          <Col lg={6} md={6} sm={12} xs={12} key={index}>

            <div>
              <div className="wsk-cp-product">
                {/* {item.dis_availablity === "Available" ? ( */}
                <Link to={`${webUrl}activity/${item.product_slug}`} state={item.prodIdArr}>
                  <div className="wsk-cp-img">
                    <img loading="lazy" src={item.product_image} alt={item.product_name} className="img-fluid" width="100%" height="auto" />
                    <div className="add-cart">
                      <WishlistButton heartToggle={tourListWishlist[item.product_id]} product={item.product_id} />
                    </div>
                  </div>
                  <div className="wsk-cp-text">

                    <div className="title-product">
                      <h3>{item.product_name}</h3>
                    </div>
                    <div className="description-prod">
                      {(item.availablity) == "Not Available" ? (
                        <p className="text-danger">{item.availablity}</p>
                      ) : <p className="text-success">{item.availablity}</p>}
                    </div>
                    <div className="card-footer">
                      <div className="wcf-left">
                        from
                        <span className="wcf-left">
                          {parseFloat(item.product_price_starts) < parseFloat(item.tick_original_price) ? (
                            <div className="containers pb-2">
                              <s>
                                <span className="currency">{selectedCurrency}</span>{" "}
                                {currConvert(item.tick_original_price)}
                              </s>

                            </div>
                          ) : null}

                        </span>
                        <span className="price">
                          <span className="currency">{selectedCurrency}</span>{" "}
                          <p className="fw-bold fs-6 d-flex align-items-center justify-content-between">
                            {currConvert(item.product_price_starts)}{parseFloat(item.product_price_starts) < parseFloat(item.tick_original_price) ? (<span className="hot_badge">{item.tick_discount} </span>) : null}
                          </p>
                        </span>
                      </div>
                      <div className="wcf-right">
                        <p>
                          <i className="bi bi-star-fill"></i> {item.product_rating}
                        </p>
                      </div>
                    </div>

                  </div>

                </Link>
                {/* ) : */}
                {/* <div>
                    <Link to={`#`} state={item.prodIdArr}>

                      <div className="wsk-cp-img">
                        <img loading="lazy" src={item.product_image} alt={item.product_name} className="img-fluid" width="100%" height="auto" />
                      </div>
                      <div className="wsk-cp-text">

                        <div className="title-product">
                          <h3>{item.product_name}</h3>
                        </div>
                        <div className="description-prod">
                          <p className="text-danger">{item.dis_availablity}</p>
                        </div>
                        <div className="card-footer">
                        <div className="wcf-left">
                          from
                          <span className="price">
                            <span className="currency">{selectedCurrency}</span>{" "}
                              {currConvert(item.product_price_starts_old)}
                          </span>
                        </div>
                          <div className="wcf-right">
                            <p>
                              <i className="bi bi-star-fill"></i> {item.product_rating}
                            </p>
                          </div>
                        </div>

                      </div>

                    </Link>
                  </div>  */}
                {/* } */}
              </div>
            </div>

          </Col>
        ))
      )}
    </>
  );
}

export default TourList;
